const simCardTransferPath = '/besitzerwechsel'

export default [
  {
    path: simCardTransferPath,
    name: 'SimCardTransferLogin',
    meta: { title: 'Besitzerwechsel Login', layout: 'order' },
    component: () => import('src/components/order/sim_card_transfer/login.vue')
  },
  {
    path: simCardTransferPath + '/meine-daten',
    name: 'SimCardTransferData',
    meta: { title: 'Besitzerwechsel Daten', layout: 'order' },
    component: () =>
      import('src/components/order/sim_card_transfer/sim_card_transfer.vue')
  },
  {
    path: simCardTransferPath + '/:id',
    name: 'SimCardTransferLoginWithId',
    meta: { title: 'Besitzerwechsel Login', layout: 'order' },
    component: () => import('src/components/order/sim_card_transfer/login.vue')
  }
]
