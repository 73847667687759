import { httpStatusCodes, messages } from 'src/lib/messages'
import VueSecureHTML from 'vue-html-secure'

const getPdfApiErrorMessage = e => {
  try {
    const decoder = new TextDecoder()
    const response = e.response || {}
    let jsonString = decoder.decode(response.data)

    if (jsonString.length === 0) {
      jsonString = '{}'
    }

    const responseData = JSON.parse(jsonString)

    return responseData.userMessage || messages.generic_api_error
  } catch (e2) {
    console.error(e2)

    return messages.generic_api_error
  }
}

const getErrorMessage = request => {
  let errorMessage = messages.generic_api_error || 'Fehler.'

  try {
    if (request && request.data) {
      const responseBody = request.data

      if (responseBody.userMessage && responseBody.userMessage.length > 5) {
        errorMessage = VueSecureHTML.safeHTML(responseBody.userMessage)
      }
    }
  } catch (e) {
    console.error('Error in Response Body and extracting UserMessage: ', e)
  }

  return errorMessage
}

const getError = request => {
  let errorMessage = messages.generic_api_error
  let errorCode = undefined
  let redirectUrl = undefined

  try {
    if (request && request.data) {
      const responseBody = request.data

      if (responseBody.userMessage && responseBody.userMessage.length > 5) {
        errorMessage = VueSecureHTML.safeHTML(responseBody.userMessage)
      }

      // FIXME: support both error code types until V2 API calls are ready
      if (responseBody.errorCode) {
        errorCode = responseBody.errorCode
      }

      if (responseBody.errors) {
        const error = responseBody.errors[0]

        if (error && error.code) {
          errorCode = error.code
        }
      }

      redirectUrl = responseBody.redirectPath || responseBody.redirectUrl
    }

    if (httpStatusCodes[request.status]) {
      errorMessage = httpStatusCodes[request.status]
    }
  } catch (e) {
    console.error('Error in Response Body and extracting UserMessage: ', e)
  }

  return { errorMessage, errorCode, redirectUrl }
}

export { getPdfApiErrorMessage, getErrorMessage, getError }
