import { bus } from 'src/plugins/bus'
import { getField, updateField } from 'vuex-map-fields'
import axios from 'src/lib/axios'

const getDefaultState = () => ({
  accountId: undefined,
  bankId: undefined,
  bankName: undefined,
  mandateAccountId: undefined,
  mandateBankId: undefined,
  mandateReference: undefined,
  isFieldValid: undefined,
  isOnlineValidated: undefined,
  onlineValidationError: undefined,
  bankDataMandatory: true,
  userBankData: {
    editStatus: {
      isEditable: false,
      message: undefined
    },
    mandateStatus: {
      isError: false,
      message: undefined
    },
    accountHolder: undefined,
    error: false,
    errorMessage: undefined
  },
  sepaMandateActivationInProgress: false
})

const state = getDefaultState()

const getters = {
  getField,
  getDto: state => {
    return {
      bankId: state.bankId || '',
      accountId: state.accountId || null,
      mandateReference: null
    }
  },
  getUserDataDto: state => {
    return {
      bankId: state.bankId || '',
      accountId: state.accountId || null
    }
  },
  getValidationDto: state => {
    return {
      bankId: state.bankId || '',
      accountId: state.accountId
    }
  },
  getMandateRequestDto: (state, getters, rootState, rootGetters) => {
    return {
      ...getters['getDto'],
      address: rootGetters['address/getDto'],
      personalData: rootGetters['personal/getDto']
    }
  }
}

const actions = {
  async validateBankDataOnline({ commit, getters }) {
    const validationDto = getters['getValidationDto']

    try {
      const response = await axios.post(
        '/rest-api/v1/sepa-mandate/iban/validate',
        validationDto
      )

      if (validationDto.bankId && validationDto.bankId !== '') {
        response.data.bankId = validationDto.bankId
      }

      response.data.accountId = validationDto.accountId
      commit('updateBankData', response.data)

      commit('bankDataValidationSuccess')

      return true
    } catch (e) {
      commit('bankDataValidationFailure')

      return false
    }
  },
  async updateUserBankData({ commit, rootGetters, dispatch }) {
    try {
      const response = await axios.get('/rest-api/v1/sepa-mandate', {
        headers: {
          ...rootGetters['authentication/getHeader']
        }
      })

      commit('updateBankData', response.data)
    } catch (e) {
      dispatch('apiErrors/handleError', e, { root: true })
    }
  },
  async submitUserBankData({ rootGetters, getters, dispatch }, callbacks) {
    const successCallback = callbacks ? callbacks.successCallback : undefined
    const errorCallback = callbacks ? callbacks.errorCallback : undefined

    try {
      await axios.put('/rest-api/v1/sepa-mandate', getters['getUserDataDto'], {
        headers: {
          ...rootGetters['authentication/getHeader']
        }
      })
      // set timestap for successful bankdate submit
      localStorage.setItem('bdst', new Date().getTime())
      if (typeof successCallback === 'function') {
        successCallback()
      } else {
        bus.emit('success', 'Bankdaten wurden erfolgreich gespeichert.')
      }
    } catch (e) {
      if (typeof errorCallback === 'function') {
        errorCallback()
      }

      dispatch('apiErrors/handleError', e, { root: true })
    }
  }
}

const mutations = {
  updateField,
  updateWithMandateData(state, bankData) {
    state.mandateAccountId = bankData.accountId
    state.mandateBankId = bankData.bankId
    state.mandateReference = bankData.mandateReference
    state.bankName = bankData.bankName

    if (bankData.updateBankId) {
      state.bankId = bankData.bankId
    }
  },
  updateViewBankData(state, field) {
    const upperCaseFields = ['accountId', 'bankId']

    if (
      upperCaseFields.includes(field.path) &&
      typeof field.value === 'string'
    ) {
      field.value = field.value.toUpperCase()
    }

    updateField(state, field)
  },
  updateBankData(state, bankData) {
    state.accountId = bankData.accountId
    state.bankId = bankData.bankId
    state.bankName = bankData.bankName
    state.mandateReference = bankData.mandateReference
    state.userBankData.accountHolder =
      bankData.accountHolder || state.userBankData.accountHolder

    if (bankData.editStatus) {
      state.userBankData.editStatus.isEditable =
        bankData.editStatus.isEditable ||
        state.userBankData.editStatus.isEditable
      state.userBankData.editStatus.message =
        bankData.editStatus.message || state.userBankData.editStatus.message
    }

    if (bankData.mandateStatus) {
      state.userBankData.mandateStatus.message =
        bankData.mandateStatus.message ||
        state.userBankData.mandateStatus.message
      state.userBankData.mandateStatus.isError =
        bankData.mandateStatus.isError ||
        state.userBankData.mandateStatus.isError
    }

    state.userBankData.error = false
    state.userBankData.errorMessage = undefined
  },
  userBankDataError(state, message) {
    state.userBankData.error = true
    state.userBankData.errorMessage = message
  },
  updateBankDataMandatory(state, mandatory) {
    state.bankDataMandatory = mandatory
  },
  bankDataValidationSuccess(state) {
    state.isOnlineValidated = true
    state.onlineValidationError = false
  },
  bankDataValidationFailure(state) {
    state.isOnlineValidated = false
    state.onlineValidationError = true
  },
  resetAccountId(state) {
    state.accountId = undefined
    state.bankId = undefined
    state.bankName = undefined
  },
  // While bankdata submit is processed by RATOR a notification should be shown to the customer
  isSepaMandateActivationInProgress(state) {
    const submitTime = localStorage.getItem('bdst')

    if (!submitTime) {
      state.sepaMandateActivationInProgress = false

      return
    }

    const diff = new Date().getTime() - submitTime

    // 3 Minutes = 180000 milliseconds
    if (diff < 180000) {
      state.sepaMandateActivationInProgress = true
    } else {
      state.sepaMandateActivationInProgress = false
      localStorage.removeItem('bdst')
    }
  },
  reset(state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  name: 'bank',
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
