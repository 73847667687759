import { bus } from 'src/plugins/bus'
import { getField, updateField } from 'vuex-map-fields'
import axios from 'src/lib/axios'

const getDefaultState = () => ({
  hasMailAddress: false,
  invoiceMedium: undefined,
  invoiceStorage: undefined
})

const state = getDefaultState()

const getters = {
  getField,
  getDto: state => {
    return {
      hasMailAddress: state.hasMailAddress,
      invoiceMedium: state.invoiceMedium,
      invoiceStorage: state.invoiceStorage
    }
  },
  getInvoiceStorage: state => {
    return state.invoiceStorage
  },
  getInvoiceMedium: state => {
    return state.invoiceMedium
  },
  getHasMailAddress: state => {
    return state.hasMailAddress
  }
}

const actions = {
  async requestAttestationsData({ rootGetters, commit, dispatch }) {
    try {
      const response = await axios.get('/rest-api/v1/customer/settings', {
        headers: { ...rootGetters['authentication/getHeader'] }
      })

      commit('setInvoiceStorage', response.data.invoiceStorage)
      commit('setInvoiceMedium', response.data.invoiceMedium)
      commit('setHasMailAddress', response.data.hasMailAddress)
    } catch (e) {
      dispatch('apiErrors/handleError', e, { root: true })
    }
  },
  async saveAttestationsData(
    { getters, rootGetters, dispatch },
    successCallback
  ) {
    try {
      await axios.post('/rest-api/v1/customer/settings', getters['getDto'], {
        headers: {
          ...rootGetters['authentication/getHeader']
        }
      })

      if (typeof successCallback === 'function') {
        successCallback()
      } else {
        bus.emit('success', 'Neue Einstellungen werden übernommen.')
      }
    } catch (e) {
      dispatch('apiErrors/handleError', e, { root: true })
    }
  }
}

const mutations = {
  updateField,
  setInvoiceStorage(state, value) {
    state.invoiceStorage = value
  },
  setInvoiceMedium(state, value) {
    state.invoiceMedium = value
  },
  setHasMailAddress(state, value) {
    state.hasMailAddress = value || false
  },
  reset(state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  name: 'attestationsData',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  getDefaultState
}
