const getDefaultState = () => ({
  notificationOpen: false
})

const state = getDefaultState()

const getters = {
  isNotificationOpen: state => {
    return state.notificationOpen
  }
}

const actions = {}

const mutations = {
  showNotification(state) {
    state.notificationOpen = true
  },
  closeNotification(state) {
    state.notificationOpen = false
  },
  setNotificationState(state, val) {
    state.notificationOpen = val
  },
  reset(state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  name: 'notification',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  getDefaultState
}
