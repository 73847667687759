import { bus } from 'src/plugins/bus'
import { getField, updateField } from 'vuex-map-fields'
import axios from 'src/lib/axios'

const getDefaultState = () => ({
  password: '',
  repeatPassword: ''
})

const state = getDefaultState()

const getters = {
  getField,
  getDto: state => {
    return {
      password: state.password
    }
  },
  getChangedPasswordDto: state => {
    return {
      oldPassword: '',
      newPassword: state.password
    }
  }
}

const mutations = {
  updateField,
  reset(state) {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  async changePassword({ getters, rootGetters, dispatch }, currentPassword) {
    try {
      const changePasswordDto = getters['getChangedPasswordDto']

      changePasswordDto.oldPassword = currentPassword

      await axios.put('/rest-api/v1/customer/password', changePasswordDto, {
        headers: {
          ...rootGetters['authentication/getHeader']
        }
      })

      bus.emit('success', 'Ihr Passwort wurde erfolgreich geändert.', true)

      return true
    } catch (e) {
      dispatch('apiErrors/handleError', e, { root: true })

      return false
    }
  }
}

export default {
  name: 'password',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  getDefaultState
}
