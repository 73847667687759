export const getQueryString = (parameters = {}) => {
  if (Object.keys(parameters).length > 0) {
    let queryParameters = []

    for (const [name, value] of Object.entries(parameters)) {
      queryParameters.push(`${name}=${String(value)}`)
    }

    return `?${queryParameters.join('&')}`
  } else {
    return null
  }
}

export const removeQueryString = url => url.split('?')[0]

export const getQueryUrl = (url, paramters = {}) => {
  const queryString = getQueryString(paramters)

  if (queryString) {
    if (url.includes('?')) {
      return url + '&' + queryString.substring(1, queryString.length)
    }

    return url + queryString
  }

  return url
}
