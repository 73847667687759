const basePath = '/'

export default [
  {
    path: basePath + 'login',
    name: 'Login',
    meta: {
      title: 'Login',
      layout: 'standalone',
      allowIndexing: true,
      isCanonical: true
    },
    props: route => ({
      redirectTo: route.query.redirect,
      reason: route.query.reason
    }),
    component: () => import('src/components/selfcare/authentication/login.vue')
  },
  {
    path: basePath + 'kennwort-vergessen',
    name: 'PasswordReset',
    meta: {
      title: 'Passwort Vergessen',
      layout: 'standalone',
      allowIndexing: true,
      isCanonical: true
    },
    component: () =>
      import(
        'src/components/selfcare/authentication/request_password_reset.vue'
      )
  },
  {
    path: basePath + 'kwreset',
    alias: basePath + 'kennwort/zuruecksetzen',
    name: 'PasswordChange',
    meta: {
      title: 'Passwort zurücksetzen',
      layout: 'standalone',
      disableAutoLogin: true,
      allowIndexing: true
    },
    component: () =>
      import('src/components/selfcare/authentication/password_change.vue')
  },
  {
    path: basePath + 'email-verification',
    name: 'EmailVerification',
    meta: {
      title: 'Email Bestätigung',
      layout: 'standalone',
      allowIndexing: false,
      disableAutoLogin: true
    },
    props: route => ({
      token: route.query.token,
      brand: route.query.brand
    }),
    component: () =>
      import('src/components/selfcare/authentication/email_verification.vue')
  },
  {
    path: basePath + 'email-verification/decline',
    name: 'EmailVerificationDecline',
    meta: {
      title: 'Email Bestätigung abbrechen und Email löschen',
      layout: 'standalone',
      allowIndexing: false,
      disableAutoLogin: true
    },
    props: route => ({
      token: route.query.token,
      brand: route.query.brand
    }),
    component: () =>
      import(
        'src/components/selfcare/authentication/email_verification_decline.vue'
      )
  }
]
