import axios from 'src/lib/axios'

const getDefaultState = () => ({
  hostName: '',
  clientBrand: '',
  url: '',
  category: '',
  chatWindowOpen: false,
  minimized: false,
  chatAvailable: false,
  uiState: undefined,
  chatId: null,
  token: '',
  messages: [],
  connection: null,
  userName: '',
  reqIdCounter: 0,
  closedByAgent: false,
  closedDueToError: false,
  rating: 0,
  email: '',
  tmpComment: '',
  notification: '',
  backgroundNotifications: 0,
  changesInterval: undefined,
  feedbackSent: false,
  historyRequested: false,
  hasEmailHistory: false
})

const state = getDefaultState()

const formatTime = date => {
  return date.toLocaleTimeString('de-DE').slice(0, 5)
}

const formatFileSize = size => {
  var i = Math.floor(Math.log(size) / Math.log(1024))

  return (size / Math.pow(1024, i)).toFixed(1) * 1 + ' ' + ['B', 'kB', 'MB'][i]
}

const getters = {
  isChatWindowOpen: state => {
    return state.chatWindowOpen
  },
  getRating: state => {
    return state.rating
  },
  userName: state => {
    return state.userName
  },
  email: state => {
    return state.email
  },
  tmpComment: state => {
    return state.tmpComment
  }
}

const actions = {
  async updateChatStatus({ commit, rootState }) {
    try {
      const appmode = rootState.authentication.appmode ? 'app' : 'web'
      const response = await axios.get(
        `/rest-api/v2/chat/availability?channel=${appmode}`
      )

      if (response.data.available) {
        commit('chatIsAvailable')
        commit('setConfiguration', {
          hostName: response.data.hostName,
          clientBrand: response.data.clientBrand,
          url: response.data.url,
          category: response.data.category
        })

        return true
      } else {
        commit('chatIsNotAvailable')
      }
    } catch (e) {
      commit('chatIsNotAvailable')
    }

    return false
  },
  async initiateChatConnection({ state, commit, dispatch, rootState }) {
    if (state.uiState === 'welcome') {
      commit('setUiState', 'loading')
      let msisdn

      if (rootState.authentication.loggedIn) {
        msisdn = rootState.authentication.msisdn
      }

      const response = await axios.post(
        `https://${state.hostName}/chatRest/chats`,
        {
          language: 'DE',
          category: state.category,
          url: state.url,
          nickname: state.userName,
          info: {
            msisdn
          }
        }
      )

      commit('setChatId', response.data.chatId)

      const interval = setInterval(() => {
        if (state.chatId < 0) {
          dispatch('getChanges')
        }

        if (state.token && state.chatId > 0) {
          dispatch('startConnection')
          clearInterval(interval)
        }
      }, 1000)
    }
  },
  async restartWebsocket({ state, dispatch }) {
    if (
      state.chatId &&
      state.token &&
      state.connection &&
      state.messages.length > 0 &&
      state.uiState === 'chat'
    ) {
      if (state.connection.close) {
        state.connection.close()
      }

      dispatch('startConnection')
    }
  },
  async endChat({ state, commit, dispatch }) {
    commit('closeChatWindow')
    if (!state.feedbackSent) {
      await dispatch('submitRating', '')
    }

    if (state.uiState === 'feedback' || state.uiState === 'history') {
      dispatch('stopChatConnection')
      commit('reset')
    }

    if (state.closedDueToError) {
      commit('reset')
    }
  },
  async startConnection({ state, commit, dispatch }) {
    try {
      let connection = await new WebSocket(
        `wss://${state.hostName}/chatRest/chats/${state.chatId}/changes?token=${state.token}`
      )

      connection.onmessage = message => {
        const changes = JSON.parse(message.data)

        if (changes.length > 0) {
          changes.forEach(change => {
            dispatch('handleChange', change)
          })
        }
      }

      connection.onerror = error => {
        console.error('--Websocket Error--', error)
      }

      connection.onclose = close => {
        console.warn('--Websocket closed--', close)
      }

      commit('setConnection', connection)
      commit('setUiState', 'chat')
    } catch (error) {
      commit('setUiState', 'chat')
      console.error(error)
      commit('closedDueToError')
    }
  },
  async getChanges({ state, dispatch }) {
    const response = await axios.get(
      `https://${state.hostName}/chatRest/chats/${state.chatId}/changes`,
      {
        headers: { 'X-novomind-iAGENT-chat-token': state.token }
      }
    )

    response.data.changes?.forEach(change => {
      dispatch('handleChange', change)
    })
  },
  async sendMessage({ state, commit }, message) {
    commit('incrementReqIdCounter')
    const reqId = state.reqIdCounter

    commit('addMessage', {
      data: message,
      type: 'outgoing',
      sender: state.userName,
      timestamp: formatTime(new Date()),
      reqId: reqId,
      ack: false
    })
    try {
      await axios.post(
        `https://${state.hostName}/chatRest/chats/${state.chatId}/messages`,
        {
          message: message,
          reqid: reqId
        },
        { headers: { 'X-novomind-iAGENT-chat-token': state.token } }
      )
      setTimeout(() => {
        commit('messageTimeoutCheck', reqId)
      }, 3000)
    } catch (error) {
      console.error(error)
      commit('messageTimeoutCheck', reqId)
    }
  },
  async handleChange({ state, commit }, change) {
    switch (change.type) {
      case 'ChatChangeInitChatId':
        commit('setChatId', change.chatId)
        commit('setToken', change.token)
        break
      case 'ChatChangeStop':
        commit('closedByAgent')
        break
      case 'ChatChangeInitNack':
        commit('closedDueToError')
        break
      case 'ChatChangeStopPolling':
        commit('closedDueToError')
        break
      case 'ChatChangeChatstepAck':
        commit('ackMessage', change.reqid)
        break
      case 'ChatChangeSendMailAllowed':
        commit('hasEmailHistory', change.sendMailAllowed)
        break
      case 'ChatChangeChatstep':
        switch (change.chatstepSemantic) {
          case 'AgentJoined':
            commit('addMessage', {
              data: `${change.nickname} ist dem Chat beigetreten.`,
              type: 'incoming',
              sender: 'System',
              timestamp: formatTime(new Date(change.timestamp))
            })
            break
          case 'AgentLeft':
            commit('addMessage', {
              data: `${change.nickname} hat den Chat verlassen.`,
              type: 'incoming',
              sender: 'System',
              timestamp: formatTime(new Date(change.timestamp))
            })
            break
          case 'ChatForwarded':
            commit('addMessage', {
              data: `${change.nickname} hat Sie an ${change.message} weitergeleitet`,
              type: 'incoming',
              sender: 'System',
              timestamp: formatTime(new Date(change.timestamp))
            })
            break
          case 'AgentMessage':
            commit('addMessage', {
              data: change.message,
              type: 'incoming',
              sender: change.nickname,
              timestamp: formatTime(new Date(change.timestamp))
            })
            if (!state.chatWindowOpen) {
              commit('backgroundNotify')
            }

            break
          case 'FileReference': {
            const fileMeta = JSON.parse(change.message)
            const response = await axios.get(
              `https://${state.hostName}/chatRest/chats/${state.chatId}/attachments/${fileMeta.id}`,
              {
                responseType: 'arraybuffer',
                headers: { 'X-novomind-iAGENT-chat-token': state.token }
              }
            )
            const blob = new Blob([response.data], {
              type: response.headers['content-type']
            })
            const objectUrl = URL.createObjectURL(blob)

            commit('addMessage', {
              data: `${change.nickname} hat Ihnen eine Datei gesendet.`,
              type: 'incoming',
              url: objectUrl,
              fileName: fileMeta.name,
              size: formatFileSize(fileMeta.size),
              sender: change.nickname,
              timestamp: formatTime(new Date(change.timestamp))
            })
            break
          }

          case 'CustomerClosed':
            commit('closedByAgent')
            break
          default:
            console.warn('##### Change not handled #####')
            console.warn(change)
            break
        }

        break
      case 'ChatChangeMetainformation': {
        // change.message is in Format "{\"typing\":false,\"nickname\":\"(innovex) Chatuser\"}"
        // needs to be parsed to JSON
        const message = JSON.parse(change.message)

        // update on agent typing status
        if (message.typing === false) {
          commit('setNotification', '')
          break
        }

        if (message.typing === true && message.nickname) {
          commit('setNotification', `${message.nickname} tippt gerade`)
          break
        }

        // update on queue position
        if (message.waitingLine && message.waitingLine.position >= 1) {
          const getMessageText = () => {
            if (message.waitingLine.position === 1) {
              return 'Ein Agent ist gleich für Sie bereit. Sie sind die nächste Person in der Warteschlange.'
            } else {
              return `Ein Agent ist gleich für Sie bereit. Vor Ihnen ${
                message.waitingLine.position === 2
                  ? 'ist eine Person'
                  : 'sind ' + (message.waitingLine.position - 1) + ' Personen'
              } in der Wartschlange. \nUngefähre Wartezeit: ${
                message.waitingLine.timeFormatDe
              }`
            }
          }

          commit('addMessage', {
            data: getMessageText(),
            type: 'incoming',
            sender: 'System',
            timestamp: formatTime(new Date())
          })
          break
        }

        console.warn('##### Change not handled #####')
        console.warn(change)
        break
      }

      default:
        console.warn('##### Change not handled #####')
        console.warn(change)
        break
    }
  },
  async submitRating({ state, commit }, comment) {
    const review = {}

    if (comment && comment !== '') {
      review.comment = comment
    }

    if (state.rating > 0) {
      review.score = state.rating

      await axios.post(
        `https://${state.hostName}/chatRest/chats/${state.chatId}/rating`,
        {
          ratingElements: [
            {
              ...review,
              index: 1
            }
          ]
        },
        {
          headers: { 'X-novomind-iAGENT-chat-token': state.token }
        }
      )
      commit('feedbackSent')
    }
  },
  async reinitializeChat({ state }) {
    await axios.post(
      `https://${state.hostName}/chatRest/chats/${state.chatId}/reinit`,
      {},
      {
        headers: { 'X-novomind-iAGENT-chat-token': state.token }
      }
    )
  },
  async sendChatHistoryAsMail({ state, commit }, mail) {
    try {
      await axios.post(
        `https://${state.hostName}/chatRest/chats/${state.chatId}/sendmail`,
        { email: mail },
        {
          headers: { 'X-novomind-iAGENT-chat-token': state.token }
        }
      )
      commit('historyRequested')
    } catch (e) {
      console.error(e)
    }
  },
  async stopChatConnection({ state }) {
    await axios.post(
      `https://${state.hostName}/chatRest/chats/${state.chatId}/stop`,
      {},
      {
        headers: { 'X-novomind-iAGENT-chat-token': state.token }
      }
    )
  },
  // This store method is specifically for when a user closes the window
  // and the chat shall be ended properly, so the agent does not have to wait
  // until the chat runs into a timeout
  // This is why fetch is used, as the "keepalive" option makes sure the request lives
  // on even after the window is closed
  // The docs for this:
  //    "The keepalive option can be used to allow the request to outlive the page."
  //
  // Axios does not support this yet, nor does it have a fetch adapter. Only use this
  // if you need it within the onunload / beforeunload window event context
  async stopChatConnectionOnWindowClose({ state }) {
    await fetch(
      `https://${state.hostName}/chatRest/chats/${state.chatId}/stop`,
      {
        method: 'POST',
        headers: { 'X-novomind-iAGENT-chat-token': state.token },
        keepalive: true
      }
    )
  },
  async userStartedTyping({ state }) {
    await axios.post(
      `https://${state.hostName}/chatRest/chats/${state.chatId}/typing/start`,
      {},
      {
        headers: { 'X-novomind-iAGENT-chat-token': state.token }
      }
    )
  },
  async userStoppedTyping({ state }) {
    await axios.post(
      `https://${state.hostName}/chatRest/chats/${state.chatId}/typing/stop`,
      {},
      {
        headers: { 'X-novomind-iAGENT-chat-token': state.token }
      }
    )
  }
}

const mutations = {
  setConfiguration(state, data) {
    state.hostName = data.hostName
    state.clientBrand = data.clientBrand
    state.url = data.url
    state.category = data.category
  },
  openChatWindow(state) {
    state.chatWindowOpen = true
  },
  closeChatWindow(state) {
    state.chatWindowOpen = false
  },
  setMinimizedState(state, minimized) {
    state.minimized = minimized
  },
  chatIsAvailable(state) {
    state.chatAvailable = true
  },
  chatIsNotAvailable(state) {
    state.chatAvailable = false
  },
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  addMessage(state, message) {
    state.messages.push(message)
  },
  setMessages(state, messages) {
    state.messages = messages
  },
  clearMessages(state) {
    state.messages = []
  },
  setChatId(state, id) {
    state.chatId = id
  },
  setToken(state, token) {
    state.token = token
  },
  setConnection(state, connection) {
    state.connection = connection
  },
  setUiState(state, uiState) {
    state.uiState = uiState
  },
  setName(state, name) {
    state.userName = name
  },
  setEmail(state, email) {
    state.email = email
  },
  setNotification(state, notification) {
    state.notification = notification
  },
  setRating(state, rating) {
    state.rating = parseInt(rating, 10)
  },
  setTmpComment(state, comment) {
    state.tmpComment = comment
  },
  incrementReqIdCounter(state) {
    state.reqIdCounter = state.reqIdCounter + 1
  },
  hasEmailHistory(state, hasEmailHistory) {
    state.hasEmailHistory = hasEmailHistory
  },
  ackMessage(state, reqId) {
    const message = state.messages.find(
      message => message.reqId === parseInt(reqId)
    )

    if (message) {
      message.ack = true
    }
  },
  messageTimeoutCheck(state, reqId) {
    const message = state.messages.find(
      message => message.reqId === parseInt(reqId)
    )

    if (message && !message.ack) {
      message.error = true
    }
  },
  removeMessage(state, reqId) {
    const index = state.messages.findIndex(
      message => message.reqId === parseInt(reqId)
    )

    state.messages.splice(index, 1)
  },
  backgroundNotify(state) {
    state.backgroundNotifications = state.backgroundNotifications + 1
  },
  clearBackgroundNotifications(state) {
    state.backgroundNotifications = 0
  },
  closedByAgent(state) {
    state.closedByAgent = true
  },
  feedbackSent(state) {
    state.feedbackSent = true
  },
  historyRequested(state) {
    state.historyRequested = true
  },
  closedDueToError(state) {
    state.closedDueToError = true
  }
}

export default {
  name: 'chat',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  getDefaultState
}
