import { getField, updateField } from 'vuex-map-fields'
import { sanitizePhonenumber } from 'src/lib/sanitizer'
import { simCardTransferSteps } from 'src/components/order/common/step/steps'
import axios from 'src/lib/axios'

const getDefaultState = () => ({
  login: {
    msisdn: undefined,
    simCardTransferId: undefined,
    error: false,
    errorMessage: undefined
  },
  order: {
    amount: undefined,
    creditorId: undefined,
    error: false,
    errorId: undefined,
    errorMessage: undefined,
    mandateReference: undefined,
    orderNumber: undefined,
    paymentDate: undefined,
    userId: undefined
  },
  ...simCardTransferSteps.getDefaultState()
})

const state = getDefaultState()

const getters = {
  getField,
  getDto: (state, getters, rootState, rootGetters) => {
    return {
      ...getters['getLoginDto'],
      ...rootGetters['bank/getDto'],
      ...rootGetters['password/getDto'],
      address: rootGetters['address/getDto'],
      advertisingPermissions: rootGetters['contactPermissions/getDto'],
      personalData: rootGetters['personal/getDto']
    }
  },
  getLoginDto: state => {
    return {
      msisdn: state.login.msisdn,
      simCardTransferId: state.login.simCardTransferId
    }
  },
  ...simCardTransferSteps.getters
}

const actions = {
  async login({ getters, commit, dispatch, rootGetters }) {
    await commit('sanitizeMsisdn')
    try {
      const response = await axios.post(
        '/rest-api/v2/sim-card-transfer/validate',
        getters['getLoginDto']
      )

      if (response.data.pack) {
        commit('tariff/updateTariffMetaData', response.data, { root: true })
      } else {
        commit('tariff/reset', null, { root: true })
      }

      commit('bank/updateBankDataMandatory', true, {
        root: true
      })

      commit('updateLoginSuccessfull')

      return true
    } catch (e) {
      dispatch('apiErrors/handleError', { ...e, silent: true }, { root: true })
      commit('updateLoginError', rootGetters['apiErrors/getErrorMessage'])

      return false
    }
  },
  async sendTransfer({ getters, commit, dispatch, rootGetters }) {
    try {
      const response = await axios.post(
        '/rest-api/v2/sim-card-transfer/order',
        getters['getDto']
      )

      commit('updateOrder', response.data)

      commit('verification/setVerificationForSimCardTransfer', true, {
        root: true
      })

      return true
    } catch (e) {
      dispatch('apiErrors/handleError', { ...e, silent: true }, { root: true })
      commit('updateOrderError', rootGetters['apiErrors/getErrorMessage'])

      return false
    }
  },
  ...simCardTransferSteps.actions
}

const mutations = {
  updateField,
  updateLoginError(state, message) {
    state.login.error = true
    state.login.errorMessage = message
  },
  updateLoginSuccessfull(state) {
    state.login.error = false
    state.login.errorMessage = undefined
  },
  updateOrder(state, order) {
    state.order = {
      ...state.order,
      ...order,
      error: false,
      errorMessage: undefined
    }
  },
  updateOrderError(state, userMessage) {
    state.order.error = true
    state.order.errorMessage = userMessage
  },
  resetError(state) {
    state.order.errorId = undefined
    state.order.error = false
    state.order.errorMessage = undefined
  },
  ...simCardTransferSteps.mutations,
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  sanitizeMsisdn(state) {
    state.login.msisdn = sanitizePhonenumber(state.login.msisdn)
  }
}

export default {
  name: 'simCardTransfer',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  getDefaultState
}
