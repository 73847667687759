const roamingCalculatorPath = '/service/roaming-calculator'

export default [
  {
    path: '/ausland',
    redirect: { name: 'RoamingCalculator' }
  },
  {
    path:
      roamingCalculatorPath +
      '/:tariffId?/:originCountry?/:destinationCountry?',
    name: 'RoamingCalculator',
    meta: {
      title: 'Roaming-Rechner',
      layout: 'portal',
      allowIndexing: true,
      isCanonical: true
    },
    component: () =>
      import(
        'src/components/portal/service/roaming_calculator/roaming_calculator.vue'
      )
  }
]
