import { getField, updateField } from 'vuex-map-fields'

const getDefaultState = () => ({
  friendMsisdn: undefined,
  mgmToken: undefined,
  promotedViaLink: false
})

const state = getDefaultState()

const getters = {
  getField,
  getDto: state => {
    if (state.promotedViaLink) {
      return {
        promotedViaLink: true,
        mgmToken: state.mgmToken
      }
    }

    return {
      friendMsisdn: state.friendMsisdn || null
    }
  },
  getFriendMsisdn: state => {
    return state.friendMsisdn
  }
}

const mutations = {
  updateField,
  promotedViaLink(state, mgmToken) {
    state.mgmToken = mgmToken
    state.promotedViaLink = true
  },
  reset(state) {
    Object.assign(state, getDefaultState())
  }
}

const actions = {}

export default {
  name: 'mgm',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  getDefaultState
}
