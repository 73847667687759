import {
  formatDataTrafficFeatures,
  formatMessageFeatures,
  formatVoiceFeatures,
  getTariffDestinationFeatures,
  getTariffFeatures
} from './features'
import { getErrorMessage } from 'src/lib/error_message_helper'
import { getField, updateField } from 'vuex-map-fields'
import { getQueryString } from 'src/lib/url'
import axios from 'src/lib/axios'

const getDefaultState = () => ({
  selectedTariffId: undefined,
  selectedOriginCountry: undefined,
  selectedOriginId: undefined,
  selectedDestinationCountry: undefined,
  selectedDestinationId: undefined,
  originCountryFeatures: {},
  homelandFeatures: {},
  destinationCountryFeatures: {},
  tariffs: {},
  countries: []
})

const state = getDefaultState()

const getCountryFeaturesApiUrl = (originId, params) => {
  return `/rest-api/v2/roaming/country-group/${originId}${getQueryString(
    params
  )}`
}

const getters = {
  getField,
  getOriginCountryId(state) {
    return state.selectedOriginId
  },
  getOriginCountryApiUrl(state) {
    const params = {
      destinationCountryGroupId: state.selectedOriginId,
      tariffId: state.selectedTariffId
    }

    return getCountryFeaturesApiUrl(state.selectedOriginId, params)
  },
  getHomelandApiUrl(state) {
    const params = {
      destinationCountryGroupId: 0,
      tariffId: state.selectedTariffId
    }

    return getCountryFeaturesApiUrl(state.selectedOriginId, params)
  },
  getDestinationCountryApiUrl(state) {
    const params = {
      destinationCountryGroupId: state.selectedDestinationId,
      tariffId: state.selectedTariffId
    }

    return getCountryFeaturesApiUrl(state.selectedOriginId, params)
  },
  formattedOriginCountryFeatures(state) {
    if (Object.keys(state.originCountryFeatures).length === 0) {
      return []
    }

    const { voice, data, sms, mms } = state.originCountryFeatures.prices

    if (state.selectedOriginId < 2) {
      return getTariffFeatures(state.originCountryFeatures)
    }

    return formatVoiceFeatures(voice, state.selectedOriginId, true).concat(
      formatMessageFeatures(sms, mms, true).concat(
        formatDataTrafficFeatures(data)
      )
    )
  },
  formattedHomelandFeatures(state) {
    if (Object.keys(state.homelandFeatures).length === 0) {
      return []
    }

    const { voice, sms, mms } = state.homelandFeatures.prices

    if (state.selectedOriginId < 2) {
      return getTariffDestinationFeatures(state.homelandFeatures)
    }

    return formatVoiceFeatures(voice, state.selectedOriginId).concat(
      formatMessageFeatures(sms, mms)
    )
  },
  formattedDestinationCountryFeatures(state) {
    if (Object.keys(state.destinationCountryFeatures).length === 0) {
      return []
    }

    const { voice, sms, mms } = state.destinationCountryFeatures.prices

    if (state.selectedOriginId < 2 && state.selectedDestinationId < 2) {
      return getTariffDestinationFeatures(state.destinationCountryFeatures)
    }

    return formatVoiceFeatures(voice, state.selectedOriginId).concat(
      formatMessageFeatures(sms, mms)
    )
  },
  hasTariffs(state) {
    return state.tariffs.length > 0
  },
  hasCountries(state) {
    return state.countries.length > 0
  }
}

const mutations = {
  updateField,
  updateTariffId(state, tariffId) {
    state.selectedTariffId = tariffId
  },
  updateTariffs(state, tariffs) {
    state.tariffs = tariffs
  },
  updateCountries(state, countries) {
    state.countries = countries
  },
  updateOriginCountry(state, country) {
    state.selectedOriginCountry = country
  },
  updateOriginId(state, countryGroupId) {
    state.selectedOriginId = countryGroupId
  },
  updateDestinationCountry(state, country) {
    state.selectedDestinationCountry = country
  },
  updateDestinationId(state, countryGroupId) {
    state.selectedDestinationId = countryGroupId
  },
  updateOriginCountryFeatures(state, features) {
    state.originCountryFeatures = features
  },
  updateHomelandFeatures(state, features) {
    state.homelandFeatures = features
  },
  updateDestinationCountryFeatures(state, features) {
    state.destinationCountryFeatures = features
  },
  reset(state) {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  async getTariffs({ commit, getters }) {
    if (getters['hasTariffs']) {
      return
    }

    try {
      const response = await axios.get('/rest-api/v2/roaming/tariffs')

      commit('updateTariffs', response.data)
    } catch (e) {
      console.error('Error fetching tariff list', getErrorMessage(e.response))
      commit('updateTariffs', {})
    }
  },
  async getCountries({ commit, getters }) {
    if (getters['hasCountries']) {
      return
    }

    try {
      const response = await axios.get(
        '/rest-api/v2/roaming/country-group-mapping'
      )

      commit('updateCountries', response.data)
    } catch (e) {
      console.error('Error fetching country list', getErrorMessage(e.response))
      commit('updateCountries', {})
    }
  },
  async getOriginCountryFeatures({ commit, getters }) {
    try {
      const response = await axios.get(getters['getOriginCountryApiUrl'])

      commit('updateOriginCountryFeatures', response.data)
    } catch (e) {
      console.error(
        'Error fetching origin country features',
        getErrorMessage(e.response)
      )
      commit('updateOriginCountryFeatures', {})
    }
  },
  async getHomelandFeatures({ commit, getters }) {
    try {
      const response = await axios.get(getters['getHomelandApiUrl'])

      commit('updateHomelandFeatures', response.data)
    } catch (e) {
      console.error(
        'Error fetching homeland features',
        getErrorMessage(e.response)
      )
      commit('updateHomelandFeatures', {})
    }
  },
  async getDestinationCountryFeatures({ commit, getters }) {
    try {
      const response = await axios.get(getters['getDestinationCountryApiUrl'])

      commit('updateDestinationCountryFeatures', response.data)
    } catch (e) {
      console.error(
        'Error fetching destination country features',
        getErrorMessage(e.response)
      )
      commit('updateDestinationCountryFeatures', {})
    }
  }
}

export default {
  name: 'roamingCalculator',
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
