import { currencyFormat } from 'src/lib/helpers'
import { timingFormat } from './helper'

const tariffFeatures = {
  allnet_flat: [
    { feature: 'Ankommende Gespräche', content: 'kostenlos' },
    { feature: 'Ankommende SMS', content: 'kostenlos' },
    { feature: 'Ankommende MMS', content: '0,39 € / MMS' },
    {
      feature: 'Nutzung von Internet',
      content:
        'Sie können Ihre Flatrate ohne Aufpreis ebenfalls in der EU & EWR nutzen.'
    }
  ],
  smart: [
    { feature: 'Ankommende Gespräche', content: 'kostenlos' },
    { feature: 'Ankommende SMS', content: 'kostenlos' },
    { feature: 'Ankommende MMS', content: '0,39 € / MMS' },
    {
      feature: 'Nutzung von Internet',
      content:
        'Sie können Ihre Flatrate ohne Aufpreis ebenfalls in der EU & EWR nutzen.'
    }
  ],
  pre_paid: [
    { feature: 'Ankommende Gespräche', content: 'kostenlos' },
    { feature: 'Ankommende SMS', content: 'kostenlos' },
    { feature: 'Ankommende MMS', content: '0,39 € / MMS' },
    {
      feature: 'Nutzung von Internet',
      content: '0,24 € / pro MB (Taktung 100kb)'
    }
  ],
  data_flat: [
    { feature: 'Ankommende Gespräche', content: 'kostenlos' },
    { feature: 'Ankommende SMS', content: 'kostenlos' },
    { feature: 'Ankommende MMS', content: '0,39 € / MMS' },
    {
      feature: 'Nutzung von Internet',
      content:
        'Sie können Ihre Flatrate ohne Aufpreis ebenfalls in der EU & EWR nutzen.'
    }
  ]
}

const tariffDestinationFeatures = {
  allnet_flat: [
    {
      feature: 'Telefonanruf',
      content:
        'Sie können Ihre Flatrate ohne Aufpreis ebenfalls in der EU & EWR nutzen.'
    },
    {
      feature: 'SMS',
      content:
        'Sie können Ihre Flatrate ohne Aufpreis ebenfalls in der EU & EWR nutzen.'
    },
    { feature: 'MMS', content: '0,69 € / MMS' }
  ],
  smart: [
    {
      feature: 'Telefonanruf',
      content:
        'Sie können Ihre Inklusiveinheiten ohne Aufpreis ebenfalls in der EU & EWR nutzen. Nach Verbrauch der Inklusiv-Einheiten 0,09 €/ Min <span class="roaming-calculator__timing">(60s / 60s)</span>.'
    },
    {
      feature: 'SMS',
      content:
        'Sie können Ihre Inklusiveinheiten ohne Aufpreis ebenfalls in der EU & EWR nutzen. Nach Verbrauch der Inklusiv-Einheiten 0,09 € / SMS.'
    },
    { feature: 'MMS', content: '0,69 € / MMS' }
  ],
  pre_paid: [
    { feature: 'Telefonanruf', content: '0,09 € / Min' },
    { feature: 'SMS', content: '0,09 € / SMS' },
    { feature: 'MMS', content: '0,69 € / MMS' }
  ],
  data_flat: [
    { feature: 'Telefonanruf', content: '0,09 € / Min' },
    { feature: 'SMS', content: '0,09 € / SMS' },
    { feature: 'MMS', content: '0,69 € / MMS' }
  ]
}

const formatVoiceFeatures = (voice, originId, incomePrices) => {
  let result = []

  if (incomePrices === true) {
    result.push({
      feature: 'Ankommende Gespräche',
      content:
        voice.priceIncoming > 0
          ? `${currencyFormat(voice.priceIncoming)} ${
              originId === '3'
                ? '<sup class="super super--roaming">*</sup>'
                : ''
            } / Min ${timingFormat(voice.timingIncoming)}`
          : 'kostenlos'
    })
  } else {
    if (voice.priceOutgoingLandline === voice.priceOutgoingMobile) {
      result.push({
        feature: 'Gespräche',
        content: `${currencyFormat(
          voice.priceOutgoingLandline
        )} / Min ${timingFormat(voice.timingOutgoing)}`
      })
    } else {
      result.push(
        {
          feature: 'Gespräche Mobilfunk',
          content: `${currencyFormat(
            voice.priceOutgoingMobile
          )} / Min ${timingFormat(voice.timingOutgoing)}`
        },
        {
          feature: 'Gespräche Festnetz',
          content: `${currencyFormat(
            voice.priceOutgoingLandline
          )} / Min ${timingFormat(voice.timingOutgoing)}`
        }
      )
    }
  }

  return result
}

const formatDataTrafficFeatures = data => {
  return [
    {
      feature: 'Nutzung von Internet',
      content: `${currencyFormat(data.price)} / ${
        data.unit
      } <span class="roaming-calculator__timing">(Taktung ${
        data.timing
      })</span>`
    }
  ]
}

const formatMessageFeatures = (sms, mms, incomePrices) => {
  let mmsContent = 'kostenlos / nicht möglich'

  if (mms) {
    mmsContent =
      mms.priceIncoming > 0
        ? `${currencyFormat(mms.priceIncoming)} / MMS`
        : `kostenlos / ${currencyFormat(mms.priceOutgoing)} / MMS`
  }

  return [
    {
      feature: incomePrices === true ? 'Ankommende SMS' : 'SMS',
      content:
        incomePrices === true
          ? `kostenlos`
          : `${currencyFormat(sms.priceOutgoing)} / SMS`
    },
    {
      feature: incomePrices === true ? 'Ankommende MMS' : 'MMS',
      content:
        incomePrices === true
          ? mmsContent
          : `${currencyFormat(mms.priceOutgoing)} / MMS`
    }
  ]
}

const getTariffFeatures = data => {
  return tariffFeatures[data && data.tariffType] || []
}

const getTariffDestinationFeatures = data => {
  return tariffDestinationFeatures[data && data.tariffType] || []
}

export {
  formatVoiceFeatures,
  formatDataTrafficFeatures,
  formatMessageFeatures,
  getTariffFeatures,
  getTariffDestinationFeatures
}
