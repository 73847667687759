import { getErrorMessage } from 'src/lib/error_message_helper.js'
import { getField, updateField } from 'vuex-map-fields'
import axios from 'src/lib/axios'

const getDefaultState = () => ({
  donatingMsisdn: undefined,
  donatingProvider: undefined,
  donatingOperator: undefined,
  portingType: undefined,
  desiredDate: undefined,
  dateType: undefined,
  provider: [],
  portingDeclaration: {
    customerNumber: undefined,
    companyName: undefined,
    customerFirstName: undefined,
    customerLastName: undefined,
    customerBirthDate: undefined,
    msisdn: undefined,
    contractEndDate: undefined,
    expirationDate: undefined,
    portingCode: undefined,
    available: false,
    type: undefined
  },
  portingDeclarationMissingFields: []
})

const state = getDefaultState()

const getters = {
  getField,
  getDto: (state, getters, rootState, rootGetters) => {
    let personalData = rootGetters['personal/getDto']
    let portingType = getters['getPortingType']
    let donatingProvider = getters['getProviderCode']

    if (
      !state.donatingMsisdn ||
      state.donatingMsisdn.length < 5 ||
      !portingType
    ) {
      return null
    }

    return {
      donatingMsisdn: state.donatingMsisdn,
      donatingProvider: donatingProvider,
      donatingOperator: state.donatingOperator,
      portingType: portingType,
      desiredDate: state.desiredDate || null,
      firstname: personalData.firstname,
      lastname: personalData.lastname,
      birthdate: personalData.birthdate
    }
  },
  getDonatingMsisdn: state => {
    return state.donatingMsisdn
  },
  hasMnp: state => {
    return !!state.portingType
  },
  hasProviders: state => {
    return state.provider.length > 0
  },
  getPortingType(state) {
    if (state.portingType === 'request') {
      return state.dateType
    }

    if (state.portingType === 'cancelled') {
      return 'vten'
    }

    return null
  },
  getProviderCode(state) {
    const provider = state.provider.find(provider => {
      return provider.name === state.donatingProvider
    })

    if (provider) {
      return provider.code
    }

    return ''
  }
}

const actions = {
  async getOperator({ getters, commit }) {
    try {
      const response = await axios.get('/rest-api/v2/mnp/operator', {
        params: { msisdn: getters['getDonatingMsisdn'] }
      })

      commit('updateOperator', response.data.operator)
    } catch (e) {
      console.error('Error fetching Operator', getErrorMessage(e.response))
      commit('updateOperator', null)
    }
  },
  async getProvider({ commit, getters }) {
    if (!getters['hasProviders']) {
      try {
        const response = await axios.get('/rest-api/v2/mnp/provider')

        commit('updateProvider', response.data)
      } catch (e) {
        console.error(
          'Error fetching provider list',
          getErrorMessage(e.response)
        )
        commit('updateProvider', [])
      }
    }
  }
}

const mutations = {
  updateField,
  updateMnpField(state, field) {
    if (field.path === 'portingType' && field.value !== 'request') {
      state.dateType = undefined
      state.desiredDate = null
    }

    if (field.path === 'dateType' && field.value !== 'termin') {
      state.desiredDate = null
    }

    updateField(state, field)
  },
  updateProvider(state, provider) {
    state.provider = provider
  },
  updateOperator(state, operator) {
    state.donatingOperator = operator
  },
  softReset(state) {
    Object.assign(state, { ...getDefaultState(), provider: state.provider })
  },
  setPortingDeclaration(state, data) {
    if (data && Object.keys(data).length > 0) {
      if (data.customerNumber) {
        fillBusinessDeclarationInformation(state, data)
      } else {
        fillPrivateDeclarationInformation(state, data)
      }
    }
  },
  setMissingField(state, data) {
    state.portingDeclarationMissingFields.push(data)
  },
  reset(state) {
    Object.assign(state, getDefaultState())
  }
}

const fillPrivateDeclarationInformation = (state, data) => {
  state.portingDeclaration.customerFirstName = missingFields(
    state,
    'customerFirstName',
    data.customerFirstName
  )
  state.portingDeclaration.customerLastName = missingFields(
    state,
    'customerLastName',
    data.customerLastName
  )
  state.portingDeclaration.customerBirthDate = missingFields(
    state,
    'customerBirthDate',
    data.customerBirthDate
  )
  state.portingDeclaration.msisdn = data.msisdn || undefined
  state.portingDeclaration.contractEndDate = data.contractEndDate || undefined
  state.portingDeclaration.expirationDate = data.expirationDate || undefined
  state.portingDeclaration.portingCode = data.portingCode || undefined
  state.portingDeclaration.available = true
  state.portingDeclaration.type = 'private'
}

const fillBusinessDeclarationInformation = (state, data) => {
  state.portingDeclaration.companyName = missingFields(
    state,
    'companyName',
    data.companyName
  )
  state.portingDeclaration.msisdn = data.msisdn || undefined
  state.portingDeclaration.customerNumber = data.customerNumber || undefined
  state.portingDeclaration.contractEndDate = data.contractEndDate || undefined
  state.portingDeclaration.expirationDate = data.expirationDate || undefined
  state.portingDeclaration.portingCode = data.portingCode || undefined
  state.portingDeclaration.available = true
  state.portingDeclaration.type = 'business'
}

const missingFields = (state, fieldName, fieldValue) => {
  if (fieldValue) {
    return fieldValue
  } else {
    state.portingDeclarationMissingFields.push(fieldName)

    return undefined
  }
}

export default {
  name: 'mobileNumberPorting',
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
