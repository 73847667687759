import { getField, updateField } from 'vuex-map-fields'
import axios from 'src/lib/axios'

const getDefaultState = () => ({
  type: 'none',
  amount: 10,
  time: 'begin',
  preconditions: {
    showComfortPayment: false,
    isComfortPaymentEnabled: false,
    isSepaMandateRequired: true,
    isSepaMandateInVerification: false,
    allowedAmounts: [{ value: 10, text: '10 €' }],
    activeTariff: {},
    activeAutoTopup: { type: 'none', amount: 10, time: 'begin' },
    isBalanceLimitExceeded: false,
    balance: 0
  },
  topupHistory: []
})

const state = getDefaultState()

const getters = {
  getField,
  getTopup: state => ({
    type: state.type,
    amount: state.amount,
    time: state.time
  }),
  getDto: state => {
    if (state.type === 'balance') {
      return { type: 'balance', time: null, amount: null }
    } else if (state.type === 'monthly') {
      return {
        type: 'monthly',
        amount: state.amount ? state.amount.toString() || '10' : '10',
        time: state.time || 'begin'
      }
    } else {
      return {
        type: 'none',
        time: null,
        amount: null
      }
    }
  },
  getTopupRequiresBankData: state => {
    return state.type !== 'none'
  },
  getTopupsInProgress: state => {
    return state.topupHistory.filter(
      el => el.timestamp + 2 * 60 * 1000 >= Date.now()
    )
  }
}

const mutations = {
  updateField,
  disableTopup(state) {
    state.type = 'none'
    state.amount = 10
    state.time = 'begin'
  },
  updateTopup(state, topup) {
    state.type = topup.type || 'none'
    state.amount = topup.amount ? parseInt(topup.amount) : 10
    state.time = topup.time ? topup.time : 'begin'
  },
  updateTopupWithDisplayProperties(state, displayTopup) {
    if (!displayTopup) {
      state.type = 'none'
      state.amount = 10
      state.time = 'begin'
    } else {
      state.type = 'balance'
      state.amount = 10
      state.time = 'begin'
    }
  },
  updateTopupPreconditions(state, preconditions) {
    if ('showComfortPayment' in preconditions) {
      state.preconditions.showComfortPayment = preconditions.showComfortPayment
    }

    if ('isComfortPaymentEnabled' in preconditions) {
      state.preconditions.isComfortPaymentEnabled =
        preconditions.isComfortPaymentEnabled
    }

    if ('isSepaMandateRequired' in preconditions) {
      state.preconditions.isSepaMandateRequired =
        preconditions.isSepaMandateRequired
    }

    if ('isSepaMandateInVerification' in preconditions) {
      state.preconditions.isSepaMandateInVerification =
        preconditions.isSepaMandateInVerification
    }

    if ('isBalanceLimitExceeded' in preconditions) {
      state.preconditions.isBalanceLimitExceeded =
        preconditions.isBalanceLimitExceeded
    }

    if (
      preconditions.allowedAmounts &&
      preconditions.allowedAmounts.length > 0
    ) {
      state.preconditions.allowedAmounts = [...preconditions.allowedAmounts]
    }

    if (preconditions.activeTariff && preconditions.activeTariff.title) {
      state.preconditions.activeTariff = { ...preconditions.activeTariff }
    }

    if (preconditions.activeAutoTopup && preconditions.activeAutoTopup.type) {
      state.preconditions.activeAutoTopup = { ...preconditions.activeAutoTopup }
    } else {
      state.preconditions.activeAutoTopup = {
        type: 'none',
        amount: 10,
        time: 'begin'
      }
    }

    if (preconditions.balance) {
      state.preconditions.balance = preconditions.balance || 0
    }
  },
  addTopup(state, topup) {
    state.topupHistory.push({
      amount: topup.amount,
      balance: topup.balance,
      timestamp: Date.now()
    })
  },
  reset(state) {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  async getTopupPreconditions({ rootGetters, commit, dispatch }) {
    try {
      const response = await axios.get('/rest-api/v3/topup/preconditions', {
        headers: rootGetters['authentication/getHeader']
      })

      const preconditions = {
        ...response.data,
        allowedAmounts: response.data.allowedAmounts.map(x => ({
          value: x,
          text: x + ' €'
        })),
        isComfortPaymentEnabled: response.data.bankData
          ? response.data.bankData.isComfortPaymentEnabled
          : false
      }

      delete preconditions.bankData

      commit('updateTopupPreconditions', preconditions)

      if (response.data.activeAutoTopup) {
        commit('updateTopup', response.data.activeAutoTopup)
      } else {
        commit('disableTopup')
      }
    } catch (e) {
      dispatch('apiErrors/handleError', e, { root: true })
    }
  },
  async saveTopupData({ getters, rootGetters, dispatch }, successCallback) {
    const topupDto = getters['getDto']

    try {
      if (topupDto.type === 'none') {
        await axios.delete('/rest-api/v3/topup/auto', {
          headers: rootGetters['authentication/getHeader']
        })
      } else {
        await axios.put('/rest-api/v3/topup/auto', topupDto, {
          headers: {
            ...rootGetters['authentication/getHeader']
          }
        })
      }

      if (typeof successCallback === 'function') {
        successCallback()
      }
    } catch (e) {
      dispatch('apiErrors/handleError', e, { root: true })
    }
  }
}

export default {
  name: 'topup',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  getDefaultState
}
