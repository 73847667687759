export default class VisualWebsiteOptimizer {
  getQueue() {
    const queue = (window._vis_opt_queue = window._vis_opt_queue || [])

    return queue
  }

  isConversionTrackingAvailable() {
    return typeof window._vis_opt_goal_conversion === 'function'
  }

  trackPurchase(tariffIds = []) {
    if (
      !this.isConversionTrackingAvailable() ||
      typeof tariffIds !== 'object'
    ) {
      return false
    }

    const queue = this.getQueue()

    queue.push(() => {
      window._vis_opt_goal_conversion(tariffIds)
    })
  }
}
