import brand from '@brand'

const fonicGenericApiError =
  'Leider haben wir den Kontakt zur Raumstation verloren. Bitte probieren Sie es in wenigen Minuten noch einmal. Vielen Dank!'
const fonicMobileGenericApiError =
  'Oh, wir haben leider gerade etwas Sand im Getriebe. Bitte probieren Sie es in wenigen Minuten noch einmal. Vielen Dank!'
const genericApiErrorMessage =
  brand.code === 'fonic' ? fonicGenericApiError : fonicMobileGenericApiError

export const messages = {
  generic_error:
    'Interner Fehler. Bitte kontaktieren Sie die Kundenbetreuung unter der Telefonnummer 0176 8888 0000.',
  generic_api_error: `${genericApiErrorMessage}`,
  blockSim_error:
    'Die SIM-Karte konnte nicht gesperrt werden. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an die Kundenbetreuung unter der Telefonnummer 0176 8888 0000.',
  daterange_error: 'Bitte geben Sie einen gültigen Datumsbereich an!',
  mnp: {
    generic_error:
      'Der bestehende Vertrag wurde nicht vom angegebenen Anbieter freigegeben. Da die angegebenen Daten nicht mit denen bei Ihrem bisherigen Anbieter übereinstimmen. Bitte beantragen Sie die Rufnummernübernahme erneut. Vielen Dank!',
    codes: {
      '0305':
        'Ihr bisheriger Anbieter teilt mit, dass der im Antrag genannte Name und das genannte Geburtsdatum nicht übereinstimmen. Bitte wenden Sie sich diesbezüglich an Ihren bisherigen Anbieter und beantragen Sie die Rufnummernmitnahme im Anschluss erneut.',
      '0302':
        'Ihr bisheriger Anbieter teilt mit, dass der im Antrag genannte Name nicht übereinstimmt. Bitte wenden Sie sich diesbezüglich an Ihren bisherigen Anbieter und beantragen Sie die Rufnummernmitnahme im Anschluss erneut.',
      '0303':
        'Ihr bisheriger Anbieter teilt mit, dass das im Antrag genannte Geburtsdatum nicht übereinstimmt. Bitte wenden Sie sich diesbezüglich an Ihren bisherigen Anbieter und beantragen Sie die Rufnummernmitnahme im Anschluss erneut.',
      '0400':
        'Ihr bisheriger Anbieter teilt mit, dass nicht ausreichend Guthaben für die Portierung und/oder keine Kündigung des Vertrages vorliegt. Bitte wenden Sie sich diesbezüglich an Ihren bisherigen Anbieter und beantragen Sie die Rufnummernmitnahme im Anschluss erneut.',
      '0401':
        'Ihr bisheriger Anbieter teilt mit, dass die Portierungserklärung fehlt. Bitte wenden Sie sich diesbezüglich an Ihren bisherigen Anbieter und beantragen Sie die Rufnummernmitnahme im Anschluss erneut.',
      '0301':
        'Für die Rufnummernmitnahme einer Firmennummer wenden Sie sich bitte an die FONIC Kundenbetreuung unter der Telefonnummer 0176 8888 0000.'
    }
  },
  requestPasswordReset: {
    success: {
      sms: 'Wir haben Ihnen eine SMS mit Ihrem neuen Passwort an Ihre Mobilfunknummer gesendet.',
      email:
        'Wir haben Ihnen eine E-Mail an die in Ihrem Kundenkonto hinterlegte Adresse geschickt. Bitte folgen Sie den darin enthaltenen Anweisungen, um das Passwort zurückzusetzen.'
    }
  }
}

export const httpStatusCodes = {
  429: 'Zu viele Versuche. Bitte probieren Sie es in wenigen Minuten noch einmal. Vielen Dank!'
}
