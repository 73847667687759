const getDefaultState = () => ({
  modalQueue: []
})

const state = getDefaultState()

const getters = {
  getOpenModal(state) {
    if (state.modalQueue.length > 0) {
      return state.modalQueue[0]
    }

    return undefined
  }
}

const actions = {}

const mutations = {
  pushModal(state, modal) {
    state.modalQueue.push(modal)
  },
  removeModal(state, modal) {
    if (state.modalQueue.length === 0) {
      return
    }

    state.modalQueue = state.modalQueue.filter(el => el !== modal)
  },
  softReset(state) {
    Object.assign(state, {
      modalQueue: state.modalQueue.filter(el => !el.startsWith('unique-'))
    })
  },
  reset(state) {
    Object.assign(state, { modalQueue: [] })
  }
}

export default {
  name: 'modal',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  getDefaultState
}
