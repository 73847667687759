import { activationSteps } from 'src/components/order/common/step/steps'
import { getField, updateField } from 'vuex-map-fields'
import { sanitizePhonenumber } from 'src/lib/sanitizer'
import axios from 'src/lib/axios'

const getDefaultState = () => ({
  login: {
    msisdn: undefined,
    activationCode: undefined,
    error: false,
    errorMessage: undefined
  },
  agentRegistration: false,
  order: {
    amount: undefined,
    creditorId: undefined,
    error: false,
    errorId: undefined,
    errorMessage: undefined,
    errorCode: undefined,
    mandateReference: undefined,
    orderNumber: undefined,
    paymentDate: undefined,
    userId: undefined
  },
  ...activationSteps.getDefaultState()
})
const state = getDefaultState()

const getters = {
  getField,

  getDto: (state, getters, rootState, rootGetters) => {
    return {
      ...getters['getLoginDto'],
      ...rootGetters['bank/getDto'],
      ...rootGetters['password/getDto'],
      ...rootGetters['mgm/getDto'],
      address: rootGetters['address/getDto'],
      advertisingPermissions: rootGetters['contactPermissions/getDto'],
      personalData: rootGetters['personal/getDto'],
      registeredByAgent: state.agentRegistration,
      topup: rootGetters['topup/getDto'],
      documentId: rootGetters['vzdoc/getVzDocId']
    }
  },

  getLoginDto: state => {
    return {
      msisdn: state.login.msisdn,
      activationCode: state.login.activationCode
    }
  },

  getRedirectUrl: state => {
    if (state.agentRegistration) {
      return '/kundenservice/freischalten'
    } else {
      return '/freischalten'
    }
  },
  ...activationSteps.getters
}

const actions = {
  async login({ getters, commit, dispatch, rootGetters }) {
    commit('sanitizeMsisdn')
    try {
      const response = await axios.post(
        '/rest-api/v1/order/activation/check',
        getters['getLoginDto']
      )

      commit('tariff/updateTariffMetaData', response.data, { root: true })
      commit(
        'topup/updateTopupWithDisplayProperties',
        response.data.product.displayTopup,
        {
          root: true
        }
      )
      commit(
        'bank/updateBankDataMandatory',
        response.data.product.bankDataMandatory,
        {
          root: true
        }
      )

      commit('updateLoginSuccessfull')

      return true
    } catch (e) {
      dispatch('apiErrors/handleError', { ...e, silent: true }, { root: true })

      const { errorMessage, errorCode, redirectUrl } =
        rootGetters['apiErrors/getError']

      if (errorCode === 'activation_invalid_brand' && redirectUrl) {
        window.location.assign(redirectUrl + getters['getRedirectUrl'])

        return false
      }

      commit('updateLoginError', errorMessage)

      return false
    }
  },
  async sendOrder({ getters, commit, dispatch, rootGetters }) {
    try {
      const response = await axios.post(
        '/rest-api/v1/order/activation',
        getters['getDto']
      )

      commit('updateOrder', response.data)
      commit('verification/setVerificationForActivation', true, { root: true })

      return true
    } catch (e) {
      dispatch('apiErrors/handleError', { ...e, silent: true }, { root: true })
      commit('updateOrderError', rootGetters['apiErrors/getError'])

      return false
    }
  },
  ...activationSteps.actions
}

const mutations = {
  updateField,
  updateLoginError(state, message) {
    state.login.error = true
    state.login.errorMessage = message
  },
  updateLoginSuccessfull(state) {
    state.login.error = false
    state.login.errorMessage = undefined
  },
  updateOrder(state, order) {
    state.order = {
      ...state.order,
      ...order,
      error: false,
      errorMessage: undefined
    }
  },
  updateOrderError(state, { errorMessage, errorCode }) {
    state.order.error = true
    state.order.errorMessage = errorMessage
    state.order.errorCode = errorCode
  },
  setAgentRegistration(state, value) {
    state.agentRegistration = value
  },
  ...activationSteps.mutations,
  resetError(state) {
    state.order.errorId = undefined
    state.order.error = false
    state.order.errorMessage = undefined
    state.order.errorCode = undefined
  },
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  sanitizeMsisdn(state) {
    state.login.msisdn = sanitizePhonenumber(state.login.msisdn)
  }
}

export default {
  name: 'activation',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  getDefaultState
}
