const checkoutPath = '/bestellen'

export default [
  {
    path: checkoutPath + '/meine-daten',
    name: 'Checkout',
    meta: {
      title: 'Bestellung - Meine Daten',
      layout: 'order',
      orderStep: {
        name: 'register',
        number: 1
      },
      allowIndexing: false
    },
    component: () => import('src/components/order/checkout/checkout.vue')
  },
  {
    path: checkoutPath + '/:tariffSlug',
    name: 'CheckoutProduct',
    meta: {
      allowIndexing: false
    },
    redirect: to => {
      return { name: 'Checkout', state: { tariff: to.params.tariffSlug } }
    }
  }
]
