const basePath = '/'
const verificationPath = '/verifikation'

export default [
  {
    path: verificationPath,
    name: 'VerificationContinueLogin',
    meta: { title: 'Verifikation Login', layout: 'order' },
    component: () =>
      import('src/components/order/verification/continue_activation.vue')
  },
  {
    path: verificationPath + '/in-bearbeitung',
    name: 'VerificationDone',
    meta: { title: 'Verifikation In-Bearbeitung', layout: 'order' },
    component: () => import('src/components/order/verification/done.vue')
  },
  {
    path: verificationPath + '/unvollstaendig',
    name: 'VerificationFailIncomplete',
    meta: { title: 'Verifikation Unvollständig', layout: 'order' },
    component: () =>
      import('src/components/order/verification/fail_incomplete.vue')
  },
  {
    path: verificationPath + '/fehler',
    name: 'VerificationFailRedo',
    meta: { title: 'Verifikation Fehler', layout: 'order' },
    component: () => import('src/components/order/verification/fail_redo.vue')
  },
  {
    path: verificationPath + '/:id',
    name: 'VerificationReEntryId',
    meta: { title: 'Verifikation Login', layout: 'order' },
    component: () =>
      import('src/components/order/verification/continue_checkout.vue')
  },
  {
    path: basePath + 'start',
    name: 'CustomerOnboarding',
    meta: {
      title: 'Email Adresse bestätigen Identifikation abschließen',
      layout: 'order',
      background: 'grey',
      orderStep: {
        name: 'thankyou',
        number: 3
      },
      allowIndexing: false,
      disableAutoLogin: true
    },
    props: route => ({
      token: route.query.token,
      brand: route.query.brand
    }),
    component: () =>
      import('src/components/order/verification/customer_onboarding.vue')
  },
  {
    path: verificationPath + '/auswahl',
    name: 'VerificationMethods',
    meta: {
      title: 'OrderPlaced',
      layout: 'order',
      orderStep: {
        name: 'thankyou',
        number: 3
      }
    },
    component: () => import('src/components/order/verification/methods.vue')
  }
]
