Number.prototype.pad = function (size) {
  var s = String(this)

  while (s.length < (size || 2)) {
    s = '0' + s
  }

  return s
}

const HOLIDAYS = [
  '01-01',
  '01-06',
  '05-01',
  '08-15',
  '10-03',
  '11-01',
  '12-24',
  '12-25',
  '12-26',
  '12-31'
]

export const isHoliday = date => {
  if (date) {
    const formattedDate = `${formatMonth(date)}-${formatDay(date)}`

    return HOLIDAYS.includes(formattedDate)
  }

  return false
}

const getToday = () => {
  let now = new Date()

  return new Date(formatIsoDate(now))
}

// set time to 00:00:00:00
export const normalizeDate = date => {
  let normalized = new Date()

  normalized.setHours(0, 0, 0, 0)

  if (date instanceof Date) {
    normalized.setMonth(date.getMonth())
    normalized.setFullYear(date.getFullYear())
    normalized.setDate(date.getDate())
  } else {
    const temp = new Date(date)

    normalized.setMonth(temp.getMonth())
    normalized.setFullYear(temp.getFullYear())
    normalized.setDate(temp.getDate())
  }

  return normalized
}

export const isWeekend = date => {
  if (!(date instanceof Date)) {
    return false
  }

  const dayOfWeek = date.getDay()

  return dayOfWeek === 0 || dayOfWeek === 6
}

export const addDays = (date, days) => {
  if (!(date instanceof Date)) {
    date = new Date(date)
  }

  if (!days) {
    return date
  }

  date.setDate(date.getDate() + days)

  return date
}

export const subDays = (date, days) => {
  return addDays(date, -days)
}

// returns the birthdate for a certain age counting from today
export const getBirthdate = years => {
  const today = getToday()

  today.setYear(today.getFullYear() - years)

  return today
}

export const getLastDateOfYear = year => {
  if (!year) {
    year = getToday().getFullYear()
  }

  return formatDate(new Date(year + 1, 0, 0))
}

export const formatDay = date => {
  return date.getDate().pad()
}

export const formatMonth = date => {
  return (date.getMonth() + 1).pad()
}

export const formatYear = date => {
  return date.getFullYear()
}

export const formatTime = date => {
  if (!(date instanceof Date)) {
    return ''
  }

  return date.getHours().pad() + ':' + date.getMinutes().pad() + ' Uhr'
}

export const formatDate = date => {
  if (!(date instanceof Date)) {
    let parsedDate = new Date(Date.parse(date))

    if (isNaN(parsedDate.getTime()) && date) {
      parsedDate = new Date(date)
    }

    if (!isNaN(parsedDate.getTime())) {
      return (
        formatDay(parsedDate) +
        '.' +
        formatMonth(parsedDate) +
        '.' +
        formatYear(parsedDate)
      )
    } else {
      return ''
    }
  }

  return formatDay(date) + '.' + formatMonth(date) + '.' + formatYear(date)
}

export const formatShortDate = date => {
  if (!(date instanceof Date)) {
    let parsedDate = new Date(Date.parse(date))

    if (isNaN(parsedDate.getTime()) && date) {
      parsedDate = new Date(date)
    }

    if (!isNaN(parsedDate.getTime())) {
      return formatDay(parsedDate) + '.' + formatMonth(parsedDate) + '.'
    } else {
      return ''
    }
  }

  return formatDay(date) + '.' + formatMonth(date) + '.'
}

export const formatDateWithMonthName = date => {
  if (!(date instanceof Date)) {
    let parsedDate = new Date(Date.parse(date))

    if (isNaN(parsedDate.getTime()) && date) {
      parsedDate = new Date(date)
    }

    if (!isNaN(parsedDate.getTime())) {
      return (
        formatDay(parsedDate) +
        '. ' +
        getMonthName(parsedDate.getMonth() + 1) +
        ' ' +
        formatYear(parsedDate)
      )
    } else {
      return ''
    }
  }

  return (
    formatDay(date) +
    '. ' +
    getMonthName(date.getMonth() + 1) +
    ' ' +
    formatYear(date)
  )
}

export const getMonthName = month => {
  if (typeof month !== 'number' || month < 1 || month > 12) {
    return ''
  }

  return 'Januar Februar März April Mai Juni Juli August September Oktober November Dezember'.split(
    ' '
  )[month - 1]
}

export const getDaysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate()
}

export const formatIsoDate = date => {
  if (!(date instanceof Date)) {
    date = new Date(Date.parse(date))
  }

  return `${date.getFullYear()}-${(date.getMonth() + 1).pad()}-${date
    .getDate()
    .pad()}`
}

export const formatGermanDateToIso = date => {
  if (!date || date.length < 6) {
    return null
  }

  const dateParts = date.split('.')

  if (!dateParts || dateParts.length < 3) {
    return null
  }

  return (
    dateParts[2] +
    '-' +
    dateParts[1].padStart(2, '0') +
    '-' +
    dateParts[0].padStart(2, '0')
  )
}

export const parseGermanDate = germanDate => {
  if (isValidGermanDate(germanDate)) {
    return new Date(formatGermanDateToIso(germanDate))
  }

  return undefined
}

export const formatDateTime = date => {
  if (!(date instanceof Date)) {
    let parsedDate = new Date(Date.parse(date))

    if (!isNaN(parsedDate.getTime())) {
      return formatDate(parsedDate) + ', ' + formatTime(parsedDate)
    } else {
      return ''
    }
  }

  return formatDate(date) + ', ' + formatTime(date)
}

export const compareGermanDate = (dateA, dateB, desc = false) => {
  if (dateA === dateB) {
    return 0
  }

  if (
    Date.parse(formatGermanDateToIso(dateA)) <
    Date.parse(formatGermanDateToIso(dateB))
  ) {
    return desc ? 1 : -1
  }

  return desc ? -1 : 1
}

// checks if date is before refDate
export const isBefore = (date, refDate = getToday()) => {
  const normalizedDate = normalizeDate(date)
  const normalizedRefDate = normalizeDate(refDate)

  return normalizedDate.getTime() < normalizedRefDate.getTime()
}

// checks if date is after refDate
export const isAfter = (date, refDate = getToday()) => {
  const normalizedDate = normalizeDate(date)
  const normalizedRefDate = normalizeDate(refDate)

  return normalizedDate.getTime() > normalizedRefDate.getTime()
}

export function isLeapYear(year) {
  return (
    year % 4 === 0 &&
    (year % 100 !== 0 || year % 1000 === 0 || year % 400 === 0)
  )
}

export function isValidGermanDate(germanDate) {
  if (
    !germanDate ||
    germanDate.length < 5 ||
    germanDate.split('.').length < 3 ||
    !germanDate.match(/^\d{1,2}.\d{1,2}.\d{4}$/)
  ) {
    return false
  }

  const day = parseInt(germanDate.split('.')[0])
  const month = parseInt(germanDate.split('.')[1])
  const year = parseInt(germanDate.split('.')[2])

  if (isNaN(month) || isNaN(day) || isNaN(year)) {
    return false
  }

  if (month < 1 || month > 12) {
    return false
  }

  if (day < 1 || day > 31) {
    return false
  }

  if ((month === 4 || month === 6 || month === 9 || month === 11) && day > 30) {
    return false
  }

  if (isLeapYear(year)) {
    if (month === 2 && day > 29) {
      return false
    }
  } else {
    if (month === 2 && day > 28) {
      return false
    }
  }

  return true
}

export function isValidGermanBirthDate(germanDate) {
  if (!isValidGermanDate(germanDate)) {
    return false
  }

  const year = parseInt(germanDate.split('.')[2])

  if (year < 1900) {
    return false
  }

  if (year > new Date(Date.now()).getFullYear()) {
    return false
  }

  if (Date.parse(formatGermanDateToIso(germanDate)) > Date.now()) {
    return false
  }

  return true
}
