const activationPath = '/kundenservice/freischalten'

export default [
  {
    path: activationPath,
    name: 'CustomerCareActivationLogin',
    meta: { title: 'Kundenservice Freischalten Login', layout: 'order' },
    props: { customerCare: true },
    component: () => import('src/components/order/activation/login.vue')
  },
  {
    path: activationPath + '/meine-daten',
    name: 'CustomerCareActivationData',
    meta: { title: 'Kundenservice Freischalten Login', layout: 'order' },
    props: { customerCare: true },
    component: () => import('src/components/order/activation/activation.vue')
  }
]
