import { getField, updateField } from 'vuex-map-fields'

const getDefaultState = () => ({
  salutation: undefined,
  firstname: undefined,
  lastname: undefined,
  birthdate: undefined,
  email: undefined,
  initialEmail: undefined,
  parentsConsent: false
})

const state = getDefaultState()

const getters = {
  getField,
  getDto: state => {
    return {
      salutation: state.salutation,
      firstname: state.firstname,
      lastname: state.lastname,
      birthdate: state.birthdate,
      email: state.email
    }
  },
  emailUpdated: state => {
    return state.email !== state.initialEmail
  }
}

const actions = {}

const mutations = {
  updateField,
  setPersonalData(state, data) {
    state.salutation = data.salutation || undefined
    state.birthdate = data.birthdate || undefined
    state.firstname = data.firstname || undefined
    state.lastname = data.lastname || undefined
    state.email = data.email || undefined
    state.initialEmail = data.email || undefined
  },
  reset(state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  name: 'personal',
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
