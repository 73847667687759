import { getField, updateField } from 'vuex-map-fields'
import axios from 'src/lib/axios'

const getDefaultState = () => ({
  fetchedTariffSlug: undefined,
  isCurrentlyFetchingTariffMetaData: false,
  error: false,
  errorMessage: undefined,
  pack: {
    id: undefined,
    type: undefined,
    title: undefined,
    alternativeTitle: undefined,
    slug: undefined,
    isRecommended: false,
    price: {
      value: undefined,
      period: undefined,
      hasRecurringCharge: true
    },
    data: {},
    talkText: {},
    legal: {
      footnoteId: undefined,
      stars: undefined
    },
    info: undefined,
    pipPath: undefined,
    optionalText: undefined,
    initialPerk: undefined
  },
  details: {
    comfortPayment: false,
    subtitle: undefined,
    features: []
  },
  vwoIds: [],
  product: {
    displayTopup: false,
    bankDataMandatory: true,
    code: {
      order: undefined,
      activation: undefined
    },
    summary: {
      prices: [],
      benefits: [],
      highlight: {
        title: undefined,
        value: undefined,
        formattedValue: undefined
      },
      totalPrice: undefined,
      formattedTotalPrice: undefined
    }
  },
  tariffDetails: {
    text: undefined,
    error: false,
    errorMessage: undefined
  }
})

const state = getDefaultState()

const getters = {
  getField,
  getTariffId: state => {
    return state.pack.id
  },
  getTrackingTariffData: state => {
    return {
      id: state.pack.slug,
      title: state.pack.title,
      monthlyCost: state.pack.price.value,
      vwoIds: state.vwoIds
    }
  },
  isCurrentlyFetchingTariffMetaData: state => {
    return state.isCurrentlyFetchingTariffMetaData
  },
  getTariffNeedsFetching: (state, getters, rootState, rootGetters) => {
    const tariffSlug = rootGetters['checkout/getTariffSlug']

    if (!tariffSlug || tariffSlug.length < 2) {
      return false
    }

    if (state.fetchedTariffSlug && state.fetchedTariffSlug === tariffSlug) {
      return false
    }

    return true
  },
  getPack: state => {
    return state.pack
  },
  getTitle: state => {
    return state.pack.title
  },
  getTitleOrAlternative: state => {
    if (state.pack.alternativeTitle) {
      return state.pack.alternativeTitle
    }

    return state.pack.title
  }
}

let fetchTariffMetaDataPromise = undefined

const actions = {
  async fetchTariffMetaData({ getters, commit, rootGetters, dispatch }) {
    if (getters['isCurrentlyFetchingTariffMetaData']) {
      await fetchTariffMetaDataPromise

      return true
    }

    if (getters['getTariffNeedsFetching']) {
      const tariffSlug = rootGetters['checkout/getTariffSlug']

      try {
        commit('resetTariffMetaData')
        commit('isCurrentlyFetchingTariffMetaData', true)

        fetchTariffMetaDataPromise = axios.get(
          `/rest-api/v1/order/meta-data/${tariffSlug}`
        )

        const response = await fetchTariffMetaDataPromise

        commit('isCurrentlyFetchingTariffMetaData', false)
        commit('updateFetchedTariffSlug', tariffSlug)
        commit(
          'topup/updateTopupWithDisplayProperties',
          response.data.product.displayTopup,
          {
            root: true
          }
        )
        commit(
          'bank/updateBankDataMandatory',
          response.data.product.bankDataMandatory,
          {
            root: true
          }
        )
        commit('updateTariffMetaData', response.data)
        commit('updateTariffDetails', undefined)
      } catch (e) {
        commit('isCurrentlyFetchingTariffMetaData', false)
        dispatch(
          'apiErrors/handleError',
          { ...e, silent: true },
          { root: true }
        )
        commit(
          'updateTariffMetaDataError',
          rootGetters['apiErrors/getErrorMessage']
        )
      }
    }
  }
}

const mutations = {
  updateField,
  updateFetchedTariffSlug(state, slug) {
    state.fetchedTariffSlug = slug
  },
  updateTariffDetails(state, details) {
    state.tariffDetails.text = details
    state.tariffDetails.error = false
    state.tariffDetails.errorMessage = undefined
  },
  updateTariffDetailsError(state, userMessage) {
    state.tariffDetails.error = true
    state.tariffDetails.errorMessage = userMessage
  },
  isCurrentlyFetchingTariffMetaData(state, value) {
    state.isCurrentlyFetchingTariffMetaData = value
  },
  updateTariffMetaData(state, metaData) {
    state.error = false
    state.errorMessage = undefined
    state.product = metaData.product
    state.pack = metaData.pack
    state.vwoIds = metaData.vwoIds
    state.details = metaData.details
  },
  updateTariffMetaDataError(state, userMessage) {
    state.error = true
    state.errorMessage = userMessage
  },
  removeError(state) {
    state.error = false
    state.errorMessage = undefined
  },
  resetTariffMetaData(state) {
    const { error, errorMessage, product, pack, vwoIds, details } =
      getDefaultState()

    Object.assign(state, {
      error,
      errorMessage,
      product,
      pack,
      vwoIds,
      details
    })
  },
  reset(state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  name: 'tariff',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  getDefaultState
}
