const EMPTY_PAGE = {
  content: null,
  template: null,
  type: null
}

const CHECKOUT_STEPS = {
  owner: 'checkoutOwner',
  address: 'checkoutAddress',
  bankaccount: 'checkoutBankaccount',
  password: 'checkoutPassword',
  agreement: 'checkoutAgreement',
  download: 'checkoutDownload',
  phonenumber: 'checkoutPhonenumber',
  referAFriend: 'checkoutReferAFriend',
  orderNow: 'checkoutOrderNow'
}

export default class GoogleTagManager {
  getDataLayer() {
    const dataLayer = (window.tefDataLayer = window.tefDataLayer || [])

    return dataLayer
  }

  push(item) {
    const dataLayer = this.getDataLayer()

    dataLayer.push(item)
  }

  track(trackingProperty, trackingData, event = null) {
    let item = {}

    if (trackingProperty === null) {
      item = trackingData
    } else {
      item = {
        [trackingProperty]: trackingData
      }
    }

    if (event) {
      item.event = event
    }

    this.push(item)
  }

  trackOrder(transactionId, shippingCosts) {
    const data = {
      transaction_id: transactionId,
      payment_monthly: 'debit',
      payment_oneoff_cost: 'debit',
      shipping_cost: shippingCosts
    }

    this.track('order', data)
  }

  trackContract(
    transactionType,
    prevTariffId,
    hasMnp,
    hasPhoneBookEntry,
    activationFee
  ) {
    const b2i = value => {
      if (value) {
        return 1
      }

      return 0
    }

    const data = {
      transaction: transactionType,
      previous_tariff: prevTariffId,
      number_portability: b2i(hasMnp),
      phonebook_entry: b2i(hasPhoneBookEntry),
      activation_fee: activationFee
    }

    this.track('contract', data)
  }

  trackOrderStep(route, tariff = undefined) {
    if (route && route.meta && route.meta.orderStep) {
      const { name, number } = route.meta.orderStep

      if (name && number) {
        const data = {
          step_name: name,
          step_number: number,
          type: 'tariffOnly',
          tariff
        }

        this.track('checkout', data, 'GAcheckout')
      }
    }
  }

  trackOrderError(errorCode) {
    const data = {
      step_name: 'error_order',
      step_number: 0,
      type: 'tariffOnly',
      error: errorCode,
      variant: 'new'
    }

    this.track('checkout', data, 'GAcheckout')
    this.trackCvError(errorCode)
  }

  trackCvError(errorCode, category = 'Order') {
    const type = errorCode.match(/[A-Z]{8}/i) ? 'CV-1' : 'CV-0'

    this.trackGAEvent(category, 'error', type, '')
  }

  trackSearch(searchTerm, searchResultCount) {
    const data = {
      name: searchTerm,
      count: searchResultCount
    }

    this.track('search', data)
  }

  trackGAEvent(category, action, label, value) {
    const data = {
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventValue: value
    }

    this.track(null, data, 'GAEvent')
  }

  updatePageId(pathSegment) {
    let page = this.getPageData()

    page.content = `${window.location.path}/${pathSegment}`

    const item = {
      page: page
    }

    this.track(null, item, 'GASinglePage')
  }

  getPageData() {
    const dataLayer = this.getDataLayer()

    for (const dataLayerItem of dataLayer) {
      const page = dataLayerItem['page']

      if (page) {
        return page
      }
    }

    return EMPTY_PAGE
  }

  trackProduct(action, position, { id, title, monthlyCost }) {
    const product = {
      action: action,
      position: position,
      type: 'prepaid',
      id: id,
      name: title,
      oneoff_cost: 0,
      monthly_cost: monthlyCost,
      rate_number: 0,
      quantity: 1
    }

    this.track('product', product, 'GAcheckout')
  }

  trackProducts(action, products) {
    const productList = []
    let productIndex = 1

    for (const product of products) {
      productList.push({
        action: action,
        position: productIndex,
        type: 'prepaid',
        id: product.id,
        name: product.title,
        oneoff_cost: 0,
        monthly_cost: product.monthlyCost,
        rate_number: 0,
        quantity: 1
      })

      productIndex++
    }

    this.track('product', productList, 'GAcheckout')
  }

  trackVerificationMethod(method) {
    this.trackEvent('identification_start', { method })
  }

  trackLoginError(error) {
    const data = {
      eventCategory: 'Login',
      eventAction: 'error',
      eventLabel: error
    }

    this.track(null, data, 'GAEvent')
  }

  trackTopup(type) {
    const data = {
      eventCategory: 'Selfcare',
      eventAction: 'Guthaben',
      eventLabel: type
    }

    this.track(null, data, 'GAEvent')
  }

  trackBooking(type, newTariffId, currentTariffId) {
    const data = {
      eventCategory: 'Selfcare',
      eventAction: type,
      eventLabel: newTariffId,
      eventCurrentTarif: currentTariffId
    }

    this.track(null, data, 'GAEvent')
  }

  trackPermissionCampaign(category, action, label) {
    const data = {
      eventCategory: category,
      eventAction: action,
      eventLabel: label
    }

    this.track(null, data, 'GAEvent')
  }

  trackPermissionCampaignClose() {
    this.trackPermissionCampaign('banner', '', '')
  }

  trackPermissionCampaignAccepted(permissionsGranted) {
    const label = permissionsGranted ? 'mit checkbox' : 'ohne checkbox'

    this.trackPermissionCampaign('banner', 'akzeptiert', label)
  }

  trackPermissionCampaignDeclined(permissionsGranted) {
    const label = permissionsGranted ? 'mit checkbox' : 'ohne checkbox'

    this.trackPermissionCampaign('banner', 'abgelehnt', label)
  }

  trackWeakPasswordHandling(passwordChanged) {
    const action = passwordChanged
      ? 'Neues Kennwort speichern'
      : 'Unsicheres Kennwort riskieren'
    const data = {
      eventCategory: 'Kennwort verbessern',
      eventAction: action
    }

    this.track(null, data, 'GAEvent')
  }

  trackCheckoutStep(tariffName, checkoutData) {
    const getCurAction = () => {
      if (checkoutData.status) {
        return `${checkoutData.step}_${checkoutData.status}`
      } else return `${checkoutData.step}`
    }

    const data = {
      event: `et.${CHECKOUT_STEPS[checkoutData.step]}`,
      eventCategory: 'Checkout',
      eventAction: getCurAction(),
      eventLabel: `2_["${tariffName}"]`,
      eventValue: ''
    }

    this.track(null, data)
  }

  trackEvent(name, value = {}) {
    const trackingEvent = {
      event: `et.${name}`,
      eventValue: {
        name,
        ...value
      }
    }

    this.track(null, { eventValue: null })
    this.track(null, trackingEvent)
  }

  trackFormSubmission(form_name, form_submit_text) {
    this.trackEvent('form_submit', { form_name, form_submit_text })
  }
}
