import GoogleTagManager from './tracking/google_tag_manager'
import VisualWebsiteOptimizer from './tracking/visual_website_optimizer'

export default class Tracking {
  constructor(trackingEnabled) {
    this.isTrackingEnabled = trackingEnabled
    this.gtm = new GoogleTagManager()
    this.vwo = new VisualWebsiteOptimizer()
  }

  getGtm() {
    return this.gtm
  }

  getVwo() {
    return this.vwo
  }
}
