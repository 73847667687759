import { createStore as _createStore } from 'vuex'
import { importAll } from 'src/lib/helpers'
import VuexPersistence from 'vuex-persist'

const vuexSession = new VuexPersistence({
  key: 'vuex',
  reducer: state => ({ ...state, password: undefined }),
  storage: window.sessionStorage
})

const authenticationSession = new VuexPersistence({
  key: 'auth',
  reducer: state => ({
    authentication: {
      persistentAuthenticationStore:
        state && state.authentication
          ? state.authentication.persistentAuthenticationStore
          : {}
    }
  }),
  storage: window.localStorage
})

const cache = []

importAll(
  cache,
  import.meta.glob('./components/**/*.store.js', { eager: true })
)
const modules = {}

cache.forEach(storeModule => (modules[storeModule.name] = storeModule))
const store = _createStore({
  state: {},
  strict: import.meta.env.NODE_ENV !== 'production',
  mutations: {},
  actions: {
    reset({ commit }) {
      Object.keys(modules).forEach(currentModule => {
        commit(currentModule + '/reset')
      })
    }
  },
  modules: modules,
  plugins: window.Cypress
    ? []
    : [vuexSession.plugin, authenticationSession.plugin]
})

if (window.Cypress) {
  window.vuex = store
}

export default store
