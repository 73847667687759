export const landingPages = [
  {
    path: '/prepaid-tarife/allnet-flat',
    name: 'LandingPageAllnetFlat',
    title: 'Allnet Flat',
    slug: 'allnet-flat'
  },
  {
    path: '/prepaid-tarife/guenstig-telefonieren',
    name: 'LandingPageGuenstigTelefonieren',
    title: 'Günstig Telefonieren',
    slug: 'guenstig-telefonieren'
  },
  {
    path: '/prepaid-tarife/handy-flatrate',
    name: 'LandingPageHandyFlatrate',
    title: 'Handy Flatrate',
    slug: 'handy-flatrate'
  },
  {
    path: '/prepaid-tarife/sms-flat',
    name: 'LandingPageSmsFlat',
    title: 'SMS Flat',
    slug: 'sms-flat'
  }
]
