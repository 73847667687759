const getDefaultState = () => ({
  mobileMenuOpen: false
})

const state = getDefaultState()

const getters = {
  isMobileMenuOpen: state => {
    return state.mobileMenuOpen
  }
}

const actions = {}

const mutations = {
  openMobileMenu(state) {
    state.mobileMenuOpen = true
  },
  closeMobileMenu(state) {
    state.mobileMenuOpen = false
  },
  reset(state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  name: 'accountBar',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  getDefaultState
}
