import { DARK, DEFAULT, THEMES } from './themes'

const getDefaultState = () => ({
  theme: undefined
})

const state = getDefaultState()

const getPreferredThemeFromSettings = () => {
  const preferColorSchemeResult = window.matchMedia(
    '(prefers-color-scheme: dark)'
  )

  if (preferColorSchemeResult && preferColorSchemeResult.matches === true) {
    return DARK
  } else {
    return DEFAULT
  }
}

const getters = {
  getTheme: state => {
    return state.theme
  }
}

const actions = {
  initTheme({ commit }) {
    const preferedTheme = getPreferredThemeFromSettings()
    const storedTheme = localStorage.getItem('theme')
    let theme

    if (storedTheme) {
      theme = storedTheme
    } else {
      theme = preferedTheme
    }

    if (THEMES.includes(theme)) {
      commit('setTheme', theme)
    }
  },
  toggleTheme({ commit, getters }) {
    const newTheme = THEMES.find(theme => theme !== getters['getTheme'])

    commit('setTheme', newTheme)
    localStorage.setItem('theme', newTheme)
  }
}

const mutations = {
  setTheme(state, theme) {
    state.theme = theme
  },
  reset(state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  name: 'settings',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  getDefaultState
}
