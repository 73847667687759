import { bus } from 'src/plugins/bus'
import { getPdfApiErrorMessage } from 'src/lib/error_message_helper'
import axios from './axios'

export const isString = s =>
  !!s &&
  (s.constructor === String || typeof s === 'string' || s instanceof String)

export const isEmpty = o =>
  o === null ||
  o === undefined ||
  o === '' ||
  o.length === 0 ||
  (Object.keys(o).length === 0 && o.constructor === Object)

export const downloadPdf = async (path, headers, name) => {
  const fileName = name + '.pdf'

  try {
    const response = await axios.get(path, {
      responseType: 'arraybuffer',
      headers: {
        ...headers,
        Accept: 'application/pdf, application/json'
      }
    })
    const blobObject = new Blob([response.data], { type: 'application/pdf' })

    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blobObject, fileName)

      return
    }

    const fileURL = URL.createObjectURL(blobObject)
    var link = document.createElement('a')

    link.setAttribute('id', 'downloadPdfLink')
    link.setAttribute('type', 'hidden')
    link.href = fileURL
    link.download = fileName

    document.body.appendChild(link)
    if (!window.Cypress) {
      link.click()
    }

    setTimeout(function () {
      document.body.removeChild(link)
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(fileURL)
    }, 1000)
  } catch (e) {
    bus.emit('error', getPdfApiErrorMessage(e))
  }
}

export const openInNewWindow = (url, openFunction = window.open) => {
  openFunction(url)
}

export const currencyFormat = number => {
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: number === 0 ? 0 : 2,
    maximumFractionDigits: number === 0 ? 0 : 2
  }).format(number)
}

export const numberFormat = number => {
  return new Intl.NumberFormat('de-DE').format(number)
}

export const importAll = (cache, r) => {
  Object.keys(r).forEach(path => {
    cache.push(r[path].default)
  })
}

export const capitalize = value => {
  if (value && (typeof value === 'string' || value instanceof String)) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
  }

  if (value === '') {
    return ''
  }

  return undefined
}

const bodyScrollElement = document.getElementsByTagName('body')[0]

export const disableScrollY = () => {
  const scrollbarWidth =
    window.innerWidth - document.documentElement.clientWidth

  bodyScrollElement.classList.add('disable__scroll')

  if (scrollbarWidth > 0) {
    document
      .querySelectorAll('[name="disable-scroll-padding"]')
      .forEach(x => (x.style.paddingRight = scrollbarWidth + 'px'))
    bodyScrollElement.style.paddingRight = scrollbarWidth + 'px'
  }
}

export const enableScrollY = () => {
  bodyScrollElement.classList.remove('disable__scroll')
  document
    .querySelectorAll('[name="disable-scroll-padding"]')
    .forEach(x => (x.style.paddingRight = 0))
  bodyScrollElement.style.paddingRight = 0
}

export const isDateInRange = (date, start, end) => {
  if (
    typeof date.getTime !== 'function' ||
    typeof start.getTime !== 'function' ||
    typeof end.getTime !== 'function'
  ) {
    throw new Error('isDateRange is expecting arguments of type Date')
  }

  return start.getTime() <= date.getTime() && date.getTime() <= end.getTime()
}

export const uniqueId = () =>
  Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString()

export const sleep = async duration => new Promise(r => setTimeout(r, duration))

// Detect browsers that don't support CSS variables
export const isLegacyBrowser = () => {
  if (window.CSS && window.CSS.supports && window.CSS.supports('(--a: 0)')) {
    return false
  } else {
    return true
  }
}

export const cleanUpStageName = name => {
  return name.toLowerCase().replace(/_/g, '').replace(/-/g, '')
}

export const camelizeSnakeCase = name => {
  const arr = name.split('_')
  const capital = arr.map(item =>
    item ? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase() : ''
  )

  return capital.join('')
}

export const waitForElem = (selector, ms) => {
  const timeOut = new Promise((resolve, reject) => {
    let id = setTimeout(() => {
      clearTimeout(id)
      reject(`Promise time out after ${ms}ms `)
    }, ms)
  })
  const elemPromise = new Promise(resolve => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector))
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector))
        observer.disconnect()
      }
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true
    })
  })

  return Promise.race([elemPromise, timeOut])
}

export const parseTrustedShopsMark = numberString => {
  const number = parseFloat(numberString.replace(',', '.'))

  if (number >= 4.5 && number <= 5.0) {
    return 'Sehr Gut'
  } else if (number >= 3.5 && number < 4.5) {
    return 'Gut'
  } else if (number >= 2.5 && number < 3.5) {
    return 'Befriedigend'
  } else if (number >= 1.5 && number < 2.5) {
    return 'Ausreichend'
  } else if (number >= 1.0 && number < 1.5) {
    return 'Mangelhaft'
  } else {
    return 'Invalid rating'
  }
}
