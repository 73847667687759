import { loadGtm, trackPage } from './tracking/google_tag_manager'
import { loadUsercentrics } from './tracking/usercentrics'
import Tracking from 'src/lib/service/tracking'

const isTrackingEnabled = () => {
  return (
    import.meta.env.VITE_APP_TRACKING_ENABLED &&
    typeof window !== 'undefined' &&
    !window.Cypress &&
    typeof window.navigator !== 'undefined' &&
    typeof window.navigator.userAgent !== 'undefined' &&
    !window.navigator.userAgent.includes('prerenderer')
  )
}

const isWebView = store => {
  return (
    store?.getters['authentication/getAppmode'] ||
    (typeof window !== 'undefined' &&
      window.location &&
      window.location.href &&
      window.location.href.includes('appmode'))
  )
}

const tracking = new Tracking(isTrackingEnabled())

const trackingPlugIn = {
  install(app, { router, store }) {
    app.config.globalProperties.$tracking = app.tracking = tracking
    router.beforeEach(to => {
      trackPage(app, to, store)
    })
    // Only add Tracking script elements in a real browser environment
    if (isTrackingEnabled()) {
      if (isWebView(store)) {
        loadGtm()
      } else {
        loadUsercentrics(app)
      }
    }
  }
}

export { trackingPlugIn, tracking }
