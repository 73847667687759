import { createRouter, createWebHistory } from 'vue-router'
import { importAll } from 'src/lib/helpers'

const baseUrl = import.meta.env.BASE_URL || '/'

const cache = []
let routes = []

importAll(
  cache,
  import.meta.glob('./components/**/*.router.js', { eager: true })
)
routes.push(...cache.reduce((a, c) => a.concat(c)))

routes.push(
  {
    path: baseUrl + 'https://*',
    beforeEnter: to => {
      const externalUrl = to.path.replace(baseUrl, '')

      location.assign(externalUrl)
    }
  },
  {
    path: '/404.html',
    name: 'NotFoundPage',
    meta: {
      title: '404 - Seite nicht gefunden',
      layout: 'error'
    },
    component: () => import('./components/404/404.vue')
  },
  {
    path: '/410.html',
    name: 'GonePage',
    meta: {
      title: '410 - Seite nicht gefunden',
      layout: 'error'
    },
    component: () => import('./components/404/404.vue')
  },
  {
    path: '/wartungsarbeiten.html',
    name: 'MaintenancePage',
    meta: {
      title: 'Wir sind bald wieder da...',
      layout: 'standalone'
    },
    component: () => import('./components/maintenance/maintenance.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    beforeEnter: () => {
      window.location.assign('/404.html')
    }
  }
)

const router = createRouter({
  history: createWebHistory(),
  path: '/',
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash
      }
    }

    // prevent scrolling to top on router.push if configured in route meta data
    if (to && to.params && to.params.scrollOnPush === false) {
      return
    }

    return { left: 0, top: 0 }
  },
  routes
})

export default router
