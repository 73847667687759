import brand from '@brand'

const initHotjar = hotjarId => {
  window.hj =
    window.hj ||
    function () {
      ;(window.hj.q = window.hj.q || []).push(arguments)
    }

  window._hjSettings = { hjid: hotjarId, hjsv: 6 }
  const hotjarScript = document.createElement('script')

  hotjarScript.async = 1
  hotjarScript.type = 'text/javascript'
  hotjarScript.setAttribute('data-usercentrics', 'Hotjar')
  hotjarScript.src =
    'https://static.hotjar.com/c/hotjar-' +
    window._hjSettings.hjid +
    '.js?sv=' +
    window._hjSettings.hjsv

  document.getElementsByTagName('head')[0].appendChild(hotjarScript)
}

const loadHotjar = () => {
  initHotjar(brand.hotjarId)
}

export { loadHotjar }
