import { getField, updateField } from 'vuex-map-fields'
import axios from 'src/lib/axios'

const getDefaultState = () => ({
  street: undefined,
  streetNumber: undefined,
  supplement: '',
  zipCode: undefined,
  city: undefined,
  country: 'Deutschland',
  telephone: '',
  isOnlineValidated: undefined,
  onlineValidationError: undefined,
  suggestedAddress: undefined
})

const state = getDefaultState()

const getters = {
  getField,
  getDto: state => {
    return {
      street: state.street,
      streetNumber: state.streetNumber,
      supplement: state.supplement || '',
      zipCode: state.zipCode,
      city: state.city,
      country: state.country,
      telephone: state.telephone || ''
    }
  }
}

const actions = {
  async validateAddressOnline({ commit, getters }) {
    try {
      await axios.post(
        '/rest-api/v1/customer/address/validate',
        getters['getDto']
      )
      commit('addressValidationSuccess')

      return true
    } catch (error) {
      commit('addressValidationFailure')

      if (error && error.response) {
        const response = error.response

        if (response.data.status === 'corrected') {
          commit('updateSuggestedAddress', response.data.correctedAddress)
        } else {
          commit('resetSuggestedAddress')
        }
      }

      return false
    }
  },
  useSuggestedAddress({ commit, state }) {
    commit('setAddressData', state.suggestedAddress)
    commit('resetSuggestedAddress')
  }
}

const mutations = {
  updateField,
  addressValidationSuccess(state) {
    state.isOnlineValidated = true
    state.onlineValidationError = false
  },
  addressValidationFailure(state) {
    state.isOnlineValidated = false
    state.onlineValidationError = true
    state.suggestedAddress = undefined
  },
  resetSuggestedAddress(state) {
    state.suggestedAddress = undefined
  },
  setAddressData(state, data) {
    state.street = data.street || undefined
    state.streetNumber = data.streetNumber || undefined
    state.supplement = data.supplement || ''
    state.zipCode = data.zipCode || undefined
    state.city = data.city || undefined
    state.country = data.country || 'Deutschland'
    state.telephone = data.telephone || ''
    state.isOnlineValidated = false
    state.onlineValidationError = false
    state.suggestedAddress = undefined
  },
  updateSuggestedAddress(state, suggestedAddress) {
    state.suggestedAddress = {
      city: suggestedAddress.city || state.city,
      country: suggestedAddress.country || state.country,
      street: suggestedAddress.street || state.street,
      streetNumber: suggestedAddress.street_number || state.streetNumber,
      supplement: suggestedAddress.supplement || state.supplement,
      telephone: suggestedAddress.telephone || state.telephone,
      zipCode: suggestedAddress.zip_code || state.zipCode
    }
  },
  reset(state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  name: 'address',
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
