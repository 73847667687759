import { bus } from 'src/plugins/bus'
import { getField, updateField } from 'vuex-map-fields'
import axios from 'src/lib/axios'

const getDefaultState = () => ({
  isLteCapable: true
})

const state = getDefaultState()

const getters = {
  getField,
  getDto: (state, getters, rootState, rootGetters) => {
    return {
      address: rootGetters['address/getDto'],
      personalData: rootGetters['personal/getDto']
    }
  }
}

const actions = {
  async requestCustomerData({ rootGetters, commit, dispatch }) {
    try {
      const response = await axios.get('/rest-api/v1/customer/data', {
        headers: rootGetters['authentication/getHeader']
      })

      commit('address/setAddressData', response.data.address, { root: true })
      commit('personal/setPersonalData', response.data.personalData, {
        root: true
      })
      commit('setLteCapability', response.data.isLteCapable)
      if (
        response.data.mnpPortingDeclaration &&
        Object.keys(response.data.mnpPortingDeclaration).length > 0
      ) {
        commit(
          'mobileNumberPorting/setPortingDeclaration',
          response.data.mnpPortingDeclaration,
          {
            root: true
          }
        )
      }
    } catch (e) {
      dispatch('apiErrors/handleError', e, { root: true })
    }
  },
  async saveCustomerData(
    { getters, rootGetters, dispatch, commit },
    successCallback
  ) {
    try {
      await axios.put('/rest-api/v1/customer/data', getters['getDto'], {
        headers: {
          ...rootGetters['authentication/getHeader']
        }
      })

      if (rootGetters['personal/emailUpdated']) {
        commit('selfcareHome/emailVerificationStarted', null, { root: true })
      }

      if (typeof successCallback === 'function') {
        successCallback()
      } else {
        bus.emit('success', 'Neue Einstellungen werden übernommen.')
      }
    } catch (e) {
      dispatch('apiErrors/handleError', e, { root: true })
    }
  }
}

const mutations = {
  updateField,
  setLteCapability(state, lteStatus) {
    state.isLteCapable = lteStatus === false ? false : true
  },
  reset(state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  name: 'customerData',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  getDefaultState
}
