import { checkoutSteps } from 'src/components/order/common/step/steps'
import { getField, updateField } from 'vuex-map-fields'
import axios from 'src/lib/axios'

const getDefaultState = () => ({
  tariffSlug: undefined,
  order: {
    amount: undefined,
    creditorId: undefined,
    paymentDate: undefined,
    userId: undefined,
    orderNumber: undefined,
    mandateReference: undefined,
    errorId: undefined,
    error: false,
    errorMessage: undefined,
    errorCode: undefined
  },
  ...checkoutSteps.getDefaultState()
})

const state = getDefaultState()

const getters = {
  getField,
  getDto: (state, getters, rootState, rootGetters) => {
    return {
      ...rootGetters['bank/getDto'],
      ...rootGetters['mgm/getDto'],
      ...rootGetters['password/getDto'],
      address: rootGetters['address/getDto'],
      advertisingPermissions: rootGetters['contactPermissions/getDto'],
      mnp: rootGetters['mobileNumberPorting/getDto'],
      personalData: rootGetters['personal/getDto'],
      tariffId: rootGetters['tariff/getTariffId'],
      topup: rootGetters['topup/getDto'],
      documentId: rootGetters['vzdoc/getVzDocId']
    }
  },
  getOrderNumber: state => {
    return state.order.orderNumber
  },
  getTariffSlug: state => {
    return state.tariffSlug
  },
  ...checkoutSteps.getters
}

const actions = {
  async sendOrder({ getters, commit, dispatch, rootGetters }) {
    try {
      const response = await axios.post(
        '/rest-api/v1/order/checkout',
        getters['getDto']
      )

      commit('updateOrder', response.data)
      commit('verification/setVerificationForActivation', false, { root: true })
    } catch (e) {
      dispatch('apiErrors/handleError', { ...e, silent: true }, { root: true })
      commit('updateOrderError', rootGetters['apiErrors/getError'])
    }
  },
  ...checkoutSteps.actions
}

const mutations = {
  updateField,
  updateOrder(state, order) {
    state.order = {
      ...state.order,
      ...order,
      error: false,
      errorMessage: undefined
    }
  },
  updateOrderError(state, { errorMessage, errorCode }) {
    state.order.error = true
    state.order.errorMessage = errorMessage
    state.order.errorCode = errorCode
  },
  updateTariffSlug(state, slug) {
    state.tariffSlug = slug
  },
  resetError(state) {
    state.order.errorId = undefined
    state.order.error = false
    state.order.errorMessage = undefined
    state.order.errorCode = undefined
  },
  ...checkoutSteps.mutations,
  reset(state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  name: 'checkout',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  getDefaultState
}
