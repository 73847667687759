const activationPath = '/freischalten'

export default [
  {
    path: activationPath,
    name: 'ActivationLogin',
    meta: {
      title: 'SIM-Karte Freischalten',
      layout: 'order',
      allowIndexing: true,
      isCanonical: true
    },
    component: () => import('src/components/order/activation/login.vue')
  },
  {
    path: activationPath + '/meine-daten',
    name: 'ActivationData',
    meta: { layout: 'order' },
    component: () => import('src/components/order/activation/activation.vue')
  }
]
