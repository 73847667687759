import { getField, updateField } from 'vuex-map-fields'
import axios from 'src/lib/axios'

const getDefaultState = () => ({
  activeFootnote: null,
  footnotes: {},
  error: false
})

const state = getDefaultState()

const getters = {
  getField,
  getParagraphs(state) {
    return state.footnotes[state.activeFootnote] || []
  }
}

const actions = {
  async getFootnote({ commit, state }, footnoteId) {
    if (footnoteId !== state.activeFootnote || state.error) {
      commit('updateActiveFootnote', footnoteId)
      commit('updateError', false)

      try {
        const response = await axios.get(`/rest-api/footnote/${footnoteId}`)
        const paragraphs = response.data
        const footnote = { [footnoteId]: paragraphs }

        commit('updateFootnotes', footnote)
      } catch (e) {
        commit('updateError', true)
      }
    }
  }
}

const mutations = {
  updateField,
  updateActiveFootnote(state, activeFootnote) {
    state.activeFootnote = activeFootnote
  },
  updateFootnotes(state, footnote) {
    state.footnotes = {
      ...state.footnotes,
      ...footnote
    }
  },
  updateError(state, error) {
    state.error = error
  },
  reset(state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  name: 'footnote',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  getDefaultState
}
