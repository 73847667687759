import { getField } from 'vuex-map-fields'
import axios from 'src/lib/axios'

const getDefaultState = () => ({
  activeTariff: undefined,
  activeTariffOptions: [],
  pendingTariff: undefined,
  pendingTariffOptions: [],
  balance: 0,
  isComfortPaymentEnabled: false,
  counter: [],
  countersUpdateMessage: undefined,
  cycleDaysLeft: 0,
  headline: undefined,
  isPendingReplacementSimActivation: undefined,
  isPendingWebShopSimActivation: undefined,
  isSimCardBlocked: undefined,
  mnpInfo: {},
  quotaUpgradeTariffOptions: [],
  showComfortPaymentNotice: undefined,
  showCreateSepaMandateNotice: undefined,
  showMnpStatus: undefined,
  showQuotaWarning: undefined,
  showTopupNotice: undefined,
  hasMnpPortingDeclaration: false,
  showTariffSwitchCustomerCareNotice: false,
  emailVerificationStatus: undefined,
  emailVerificationStarted: false,
  showDataUsagePrediction: false,
  hasSufficientDataUsageLeftForCycle: false,
  daysWithReducedBandwidth: 0,
  hasActiveSepaMandate: false
})

const state = getDefaultState()

const getters = {
  getField,
  getBalance: state => state.balance
}

const actions = {
  async fetchSelfcareData({ rootGetters, commit, dispatch }) {
    try {
      const response = await axios.get('/rest-api/v4/home', {
        headers: {
          ...rootGetters['authentication/getHeader']
        }
      })

      commit('updateSelfcareData', response.data)
    } catch (e) {
      dispatch('apiErrors/handleError', e, { root: true })
    }
  }
}

const mutations = {
  updateSelfcareData(state, data) {
    state.activeTariff = data.activeTariff || undefined
    state.activeTariffOptions = data.activeTariffOptions || []
    state.pendingTariff = data.pendingTariff || undefined
    state.pendingTariffOptions = data.pendingTariffOptions || []
    state.balance = data.balance || 0
    state.isComfortPaymentEnabled = data.isComfortPaymentEnabled || false
    state.counter = data.counter || []
    state.countersUpdateMessage = data.countersUpdateMessage || undefined
    state.cycleDaysLeft = data.cycleDaysLeft || 0
    state.headline = data.headline || undefined
    state.isPendingReplacementSimActivation =
      data.isPendingReplacementSimActivation || undefined
    state.isPendingWebShopSimActivation =
      data.isPendingWebShopSimActivation || undefined
    state.isSimCardBlocked = data.isSimCardBlocked || undefined
    state.mnpInfo = data.mnpInfo || undefined
    state.quotaUpgradeTariffOptions = data.quotaUpgradeTariffOptions || []
    state.showComfortPaymentNotice = data.showComfortPaymentNotice || undefined
    state.showCreateSepaMandateNotice =
      data.showCreateSepaMandateNotice || undefined
    state.showMnpStatus = data.showMnpStatus || undefined
    state.showQuotaWarning = data.showQuotaWarning || undefined
    state.showTopupNotice = data.showTopupNotice || undefined
    state.activeBrandCode = data.activeBrandCode || undefined
    state.hasMnpPortingDeclaration = data.hasMnpPortingDeclaration || undefined
    state.showTariffSwitchCustomerCareNotice =
      data.showTariffSwitchCustomerCareNotice || undefined
    state.emailVerificationStatus = data.emailVerificationStatus || undefined
    state.showDataUsagePrediction = data.showDataUsagePrediction
    state.hasSufficientDataUsageLeftForCycle =
      data.hasSufficientDataUsageLeftForCycle
    state.daysWithReducedBandwidth = data.daysWithReducedBandwidth
    state.hasActiveSepaMandate = data.hasActiveSepaMandate || false
  },
  emailVerificationStarted(state) {
    state.emailVerificationStarted = true
  },
  setEmailVerificationStatus(state, verficicationStatus) {
    state.emailVerificationStatus = verficicationStatus
  },
  reset(state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  name: 'selfcareHome',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  getDefaultState
}
