export const sanitizePhonenumber = number => {
  return number
    .replace(/\s/g, '')
    .replace(/-/g, '')
    .replace(/^((0)+|(\+))49(0)*/, '0')
}

export const sanitizeIban = iban => {
  return iban.replace(/\s/g, '').replace(/-/g, '').replace(/\./g, '')
}

export const sanitizeRedirect = path => {
  if (!path || path === '') {
    return undefined
  }

  let result = path
    .replace(/\s/g, '')
    .replace(/\./g, '')
    .replace(/http(s)*/g, '')
    .replace(/:/g, '')
    .replace(/@/g, '')
    .replace(/\/\//g, '')

  if (!result.startsWith('/')) {
    result = '/' + result
  }

  return result
}
