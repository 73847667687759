const timingFormat = timing => {
  let [firstCycle, followingCycles] = timing.split('/')

  if (!firstCycle || !followingCycles) {
    return ''
  }

  return `<span class="roaming-calculator__timing">(${firstCycle}s / ${followingCycles}s)</span>`
}

const filterObject = (object, filter) => {
  const newObject = {}

  if (typeof filter !== 'function') {
    throw new Error(`Filter callback is not a function, but: ${typeof filter}`)
  }

  for (const key of Object.keys(object)) {
    const value = object[key]

    if (filter(key, value)) {
      newObject[key] = value
    }
  }

  return newObject
}

const mapToList = map => {
  let list = []

  for (const [key, value] of Object.entries(map)) {
    list.push({ value: key, text: value })
  }

  return list
}

const countryMapToList = map => {
  let list = []

  for (const key of Object.keys(map)) {
    list.push(key)
  }

  return list
}

const mapOriginCountries = countryMap => {
  countryMap = filterObject(countryMap, (key, value) => {
    const excluded = ['0', 'Deutschland']

    return !excluded.includes(key) && !excluded.includes(value)
  })

  return countryMapToList(countryMap)
}

const formatCountries = countries => {
  return countries.map(country => `${country.flag}   ${country.name}`)
}

export {
  timingFormat,
  mapToList,
  countryMapToList,
  mapOriginCountries,
  filterObject,
  formatCountries
}
