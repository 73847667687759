import brand from '@brand'

const servicePath = '/selfcare/service'

export default [
  {
    path: servicePath,
    name: 'SelfcareServiceHome',
    meta: { title: 'Selfcare Services', layout: 'selfcare' },
    component: () => import('src/components/selfcare/service/home/home.vue')
  },
  {
    path: servicePath + '/besitzerwechsel',
    name: 'SelfcareServiceSimCardTransfer',
    meta: { title: 'Besitzerwechsel', layout: 'selfcare' },
    component: () =>
      import(
        'src/components/selfcare/service/sim_card_transfer/sim_card_transfer.vue'
      )
  },
  {
    path: servicePath + '/rufnummernuebernahme',
    name: 'SelfcareServiceMnp',
    meta: { title: 'Rufnummerübernahme', layout: 'selfcare' },
    component: () => import('src/components/selfcare/service/mnp/mnp.vue')
  },
  {
    path: servicePath + '/guthaben-ausbezahlen',
    name: 'SelfcareServiceRefund',
    meta: { title: 'Guthaben-Auszahlen', layout: 'selfcare' },
    component: () => import('src/components/selfcare/service/refund/refund.vue')
  },
  {
    path: servicePath + '/sim-karte-sperren',
    name: 'SelfcareServiceBlockSim',
    meta: { title: 'SIM-Karte sperren', layout: 'selfcare' },
    component: () =>
      import('src/components/selfcare/service/block_sim/block_sim.vue')
  },
  {
    path: servicePath + '/ersatzkarte-beantragen',
    name: 'SelfcareServiceReplacementSim',
    meta: { title: 'Erstatz-SIM bestellen', layout: 'selfcare' },
    component: () =>
      import(
        'src/components/selfcare/service/replacement_sim/replacement_sim.vue'
      )
  },
  {
    path: servicePath + '/handy-einstellungen',
    name: 'SelfcareServiceCellphoneSettings',
    meta: { title: 'Handy-Einstellungen', layout: 'selfcare' },
    component: () =>
      import(
        'src/components/selfcare/service/cellphone_settings/cellphone_settings.vue'
      )
  },
  {
    path: servicePath + '/datenauskunft',
    name: 'SelfcareServiceDataExport',
    meta: { title: 'Datenauskunft', layout: 'selfcare' },
    component: () =>
      import('src/components/selfcare/service/data_transfer/data_export.vue')
  },
  {
    path: servicePath + '/datenabzug',
    name: 'SelfcareServiceSummaryExport',
    meta: { title: 'Datenabzug', layout: 'selfcare' },
    component: () =>
      import('src/components/selfcare/service/data_transfer/summary_export.vue')
  },
  {
    path: servicePath + '/0900-nummern-sperren',
    name: 'SelfcareServiceBan0900',
    meta: { title: '0900 Nummern Sperren', layout: 'selfcare' },
    component: () =>
      import('src/components/selfcare/service/ban_0900/ban_0900.vue')
  },
  // Portal duplicates for different layout and path:
  {
    path: servicePath + '/faq/:category?/:subcategory?',
    name: 'SelfcareServiceFaq',
    meta: { title: 'FAQs', layout: 'selfcare' },
    component: () => import('src/components/portal/service/faq/faqs.vue')
  },
  {
    path: servicePath + `/${brand.code}-app`,
    name: 'SelfcareServiceApp',
    meta: { title: `/${brand.name} App`, layout: 'selfcare' },
    component: () => import('src/components/portal/service/app/app.vue')
  },
  {
    path: servicePath + '/formulare',
    name: 'SelfcareServiceDownloads',
    meta: {
      title: `Formulare und Downloads`,
      layout: 'selfcare'
    },
    component: () =>
      import('src/components/portal/service/downloads/downloads.vue')
  }
]
