import { bus } from 'src/plugins/bus'
import { getErrorMessage } from 'src/lib/error_message_helper.js'
import { getField, updateField } from 'vuex-map-fields'
import axios from 'src/lib/axios'
import brand from '@brand'

export const getDefaultState = () => ({
  permissionMap: {
    PERMISSION_PROCESSING_EMAIL: true,
    PERMISSION_PROCESSING_MSISDN: true,
    PERMISSION_PROCESSING_POST: true,
    PERMISSION_PROCESSING_PERSONAL_DATA: true,
    PERMISSION_PRODUCT_ADVERTISING_EMAIL: false,
    PERMISSION_PRODUCT_ADVERTISING_SMS: false,
    PERMISSION_PRODUCT_ADVERTISING_PHONE: false,
    PERMISSION_MARKET_RESEARCH_EMAIL: false,
    PERMISSION_MARKET_RESEARCH_SMS: false,
    PERMISSION_MARKET_RESEARCH_PHONE: false,
    PERMISSION_USAGE_PROFILE_INVENTORY_DATA: false,
    PERMISSION_USAGE_PROFILE_TRAFFIC_DATA: false,
    PERMISSION_INVENTORY_DATA_USAGE_PROFILE_INVENTORY_DATA: false,
    PERMISSION_INVENTORY_DATA_USAGE_PROFILE_TRAFFIC_DATA: false
  },
  permissions: [
    {
      id: 'GROUP_PROCESSING',
      title: 'Hinweis zur Verarbeitung von Daten mit gesetzlicher Erlaubnis',
      elements: [
        'PERMISSION_PROCESSING_EMAIL',
        'PERMISSION_PROCESSING_MSISDN',
        'PERMISSION_PROCESSING_POST',
        'PERMISSION_PROCESSING_PERSONAL_DATA'
      ]
    },
    {
      id: 'assentation',
      title: 'Einwilligung',
      isCollapseGroup: true
    },
    {
      id: 'GROUP_PRODUCT_ADVERTISING',
      title: 'Einwilligung',
      subtitle: `Informationen zu ${brand.name}-Produkten`,
      collapseGroup: 'assentation',
      elements: [
        'PERMISSION_PRODUCT_ADVERTISING_EMAIL',
        'PERMISSION_PRODUCT_ADVERTISING_SMS',
        'PERMISSION_PRODUCT_ADVERTISING_PHONE'
      ]
    },
    {
      id: 'GROUP_MARKET_RESEARCH',
      subtitle: 'Befragung zu Marktforschungszwecken',
      collapseGroup: 'assentation',
      elements: [
        'PERMISSION_MARKET_RESEARCH_EMAIL',
        'PERMISSION_MARKET_RESEARCH_SMS',
        'PERMISSION_MARKET_RESEARCH_PHONE'
      ]
    },
    {
      id: 'GROUP_USAGE_PROFILE',
      subtitle: 'Erstellung eines Nutzungsprofils',
      collapseGroup: 'assentation',
      elements: [
        'PERMISSION_USAGE_PROFILE_INVENTORY_DATA',
        'PERMISSION_USAGE_PROFILE_TRAFFIC_DATA'
      ]
    },
    {
      id: 'GROUP_INVENTORY_DATA_USAGE_PROFILE',
      subtitle: 'Erstellung eines Bestandsdaten Nutzungsprofils',
      collapseGroup: 'assentation',
      elements: [
        'PERMISSION_INVENTORY_DATA_USAGE_PROFILE_INVENTORY_DATA',
        'PERMISSION_INVENTORY_DATA_USAGE_PROFILE_TRAFFIC_DATA'
      ]
    }
  ],
  collapseGroups: {
    assentation: {
      collapsed: true
    }
  },
  msisdn: undefined,
  error: false,
  errorMessage: undefined
})

const state = getDefaultState()

const getters = {
  getField,

  getDto: state => {
    return Object.keys(state.permissionMap).map(key => {
      return {
        textId: key,
        selected: state.permissionMap[key]
      }
    })
  },

  renderedPermissions: (state, getters) => {
    return state.permissions
      .filter(
        // remove collapsed elements
        x =>
          (x.isCollapseGroup === undefined &&
            !getters.isGroupCollapsed(x.collapseGroup)) ||
          (x.isCollapseGroup && getters.isGroupCollapsed(x.id))
      )
      .map(x => {
        // remove elements which are not in permissionMap
        const xCopy = { ...x }

        if (x.elements) {
          xCopy.elements = x.elements.filter(y =>
            Object.keys(state.permissionMap).includes(y)
          )
        }

        return xCopy
      })
      .filter(
        // remove elements which not in the permissionsMap and have not children in the permissionMap
        x =>
          x.isCollapseGroup ||
          Object.keys(state.permissionMap).includes(x.id) ||
          (x.elements && x.elements.length > 0)
      )
  },

  getGroupPermissionValue: state => groupId => {
    const permissionGroup = state.permissions.find(x => x.id === groupId)

    if (permissionGroup && permissionGroup.elements) {
      return permissionGroup.elements
        .map(x => state.permissionMap[x])
        .reduce((a, b) => a && b)
    }

    return false
  },

  isGroupPermissionStateMixed: state => groupId => {
    const permissionGroup = state.permissions.find(x => x.id === groupId)

    if (permissionGroup && permissionGroup.elements) {
      let checkboxStateCounter = permissionGroup.elements
        .map(x => state.permissionMap[x])
        .reduce((a, b) => a + b)

      return (
        checkboxStateCounter !== permissionGroup.elements.length &&
        checkboxStateCounter !== 0
      )
    } else {
      return false
    }
  },

  getCollapsedGroupValue: state => collapseGroupId => {
    if (collapseGroupId) {
      if (state.collapseGroups && state.collapseGroups[collapseGroupId]) {
        let result = false

        state.permissions
          .filter(x => x.collapseGroup === collapseGroupId)
          .flatMap(x => [x.id, ...x.elements])
          .forEach(permissionId => {
            if (
              state.permissionMap &&
              state.permissionMap[permissionId] !== undefined
            ) {
              result = result || state.permissionMap[permissionId]
            }
          })

        return result
      }
    }

    return false
  },

  isGroupCollapsed: state => collapseGroupId => {
    if (collapseGroupId) {
      if (state.collapseGroups && state.collapseGroups[collapseGroupId]) {
        if (!(state.collapseGroups[collapseGroupId].collapsed === true)) {
          return false
        }

        const collapsedPermissions = state.permissions
          .filter(x => x.collapseGroup === collapseGroupId)
          .flatMap(x => [x.id, ...x.elements])
          .map(permissionId => state.permissionMap[permissionId])

        if (
          collapsedPermissions.includes(true) &&
          collapsedPermissions.includes(false)
        ) {
          return false
        }

        return true
      }
    }

    return false
  },

  getGuestPermissionsDto: state => {
    return {
      msisdn: state.msisdn
    }
  }
}

const actions = {
  async getGuestPermissions({ commit, getters }) {
    try {
      const response = await axios.post(
        '/rest-api/v1/order/contact-permissions',
        getters['getGuestPermissionsDto']
      )

      commit('updateContactPermissions', response.data)
    } catch (e) {
      commit('error', getErrorMessage(e.response))
    }
  },

  async getUserPermissions({ dispatch, commit, rootGetters }) {
    try {
      const response = await axios.get(
        '/rest-api/v1/customer/contact-permissions',
        {
          headers: rootGetters['authentication/getHeader']
        }
      )

      commit('updateContactPermissions', response.data)
    } catch (e) {
      dispatch('apiErrors/handleError', e, { root: true })
    }
  },

  async submitUserPermissions(
    { dispatch, getters, rootGetters },
    successCallback
  ) {
    try {
      await axios.post(
        '/rest-api/v1/customer/contact-permissions',
        getters['getDto'],
        {
          headers: rootGetters['authentication/getHeader']
        }
      )
      if (typeof successCallback === 'function') {
        successCallback()
      } else {
        bus.emit('success', 'Neue Einwilligungserklärung werden übernommen.')
      }
    } catch (e) {
      dispatch('apiErrors/handleError', e, { root: true })
    }
  }
}

const mutations = {
  updateField,

  updateContactPermissions(state, { permissionMap }) {
    state.permissionMap = {
      ...permissionMap
    }

    state.error = false
  },

  togglePermission(state, id) {
    if (id) {
      if (state.permissionMap[id] !== undefined) {
        state.permissionMap[id] = !state.permissionMap[id]
      }
    }
  },

  togglePermissionGroup(state, groupId) {
    if (groupId) {
      const permissionGroup = state.permissions.find(x => x.id === groupId)

      if (permissionGroup && permissionGroup.elements) {
        const value = permissionGroup.elements
          .map(id => state.permissionMap[id])
          .reduce((a, b) => a && b)

        permissionGroup.elements.forEach(id => {
          state.permissionMap[id] = !value
        })
      }
    }
  },

  expandCollapsedGroup(state, collapseGroupId) {
    if (collapseGroupId) {
      if (state.collapseGroups && state.collapseGroups[collapseGroupId]) {
        state.collapseGroups[collapseGroupId].collapsed = false
      }
    }
  },

  setCollapsedGroupValue(state, { collapseGroupId, value }) {
    if (collapseGroupId !== undefined && value !== undefined) {
      if (state.collapseGroups[collapseGroupId]) {
        state.permissions
          .filter(x => x.collapseGroup === collapseGroupId)
          .flatMap(x => [x.id, ...x.elements])
          .forEach(permissionId => {
            if (
              state.permissionMap &&
              state.permissionMap[permissionId] !== undefined
            ) {
              state.permissionMap[permissionId] = value
            }
          })
      }
    }
  },

  updateMsisdn(state, msisdn) {
    state.msisdn = msisdn
  },

  error(state, message) {
    state.error = true
    state.errorMessage = message
  },

  reset(state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  name: 'contactPermissions',
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
